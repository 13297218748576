import { createContext, useState } from 'react';

const INITIAL_VALUES = {
  isLoadingGeneralSettings: false,
  setIsLoadingGeneralSettings: (v) => {},
  fetchGeneralSettingsCount: 0,
  setFetchGeneralSettingsCount: (v) => {},
  fetchedGeneralSettings: null,
  setFetchedGeneralSettings: (v) => {}
};

const GeneralSettingsContext = createContext(INITIAL_VALUES);

export const GeneralSettingsProvider = ({ children }) => {
  const [isLoadingGeneralSettings, setIsLoadingGeneralSettings] = useState(
    INITIAL_VALUES?.isLoadingGeneralSettings
  );
  const [fetchGeneralSettingsCount, setFetchGeneralSettingsCount] = useState(
    INITIAL_VALUES?.fetchGeneralSettingsCount
  );
  const [fetchedGeneralSettings, setFetchedGeneralSettings] = useState(
    INITIAL_VALUES?.fetchedGeneralSettings
  );

  return (
    <GeneralSettingsContext.Provider
      value={{
        isLoadingGeneralSettings,
        setIsLoadingGeneralSettings,
        fetchGeneralSettingsCount,
        setFetchGeneralSettingsCount,
        fetchedGeneralSettings,
        setFetchedGeneralSettings
      }}
    >
      {children}
    </GeneralSettingsContext.Provider>
  );
};

export default GeneralSettingsContext;
