/* eslint-disable eqeqeq */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import successImg from '../../assets/imgs/icons/success.png';
import routerLinks from '../../components/app/routerLinks';
import { paymentTypesValues } from '../../const-values/paymentTypes';
import CartContext from '../../contexts/cart-context/CartContext';
import './CartStepThree.scss';

const CartStepThree = () => {
  const { t } = useTranslation();
  // const { step, setStep, isLastStep, childrenArray } = useContext(
  //   CustomStepperWrapContext
  // );
  const { cartOrderRes, paymentType } = useContext(CartContext);

  return (
    <div className="stepper-step cart-step-three">
      <div className="step-content">
        <img className="success-img" src={successImg} alt="success" />
        <h2 className="success-text bold-font">
          {t('userCartPage.successPayment')}
        </h2>
        <p className="thanks-text">{t('userCartPage.thanks')}</p>

        {paymentType == paymentTypesValues?.onlinePayment ? (
          <a
            // target="_blank"
            href={`${
              process.env.REACT_APP_BASE_URL
            }/Payment/preparePayment?order_id=${cartOrderRes?.id || ''}`}
            className="my-orders-link"
            to={routerLinks?.myOrders}
          >
            {t('userCartPage.pay')}
            {/* {t('userCartPage.myOrdersBtn')} */}
          </a>
        ) : (
          <Link className="my-orders-link" to={routerLinks?.myOrders}>
            {t('userCartPage.myOrdersBtn')}
          </Link>
        )}
      </div>
    </div>
  );
};

export default CartStepThree;
