/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'antd';
import errorNotification from '../../../utils/errorNotification';
import { useTranslation } from 'react-i18next';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import successNotification from '../../../utils/successNotification';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import UserContext from '../../../contexts/user-context/UserProvider';
import './CloseMerchentModal.scss';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import UserIcon from '../../../common/icons/UserIcon';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import closeMerchentAccountApi from '../../../apis/auth/closeMerchentAccountApi';
import checkRes from '../../../utils/checkRes';
import LoadingModal from '../../../common/loading-modal/LoadingModal';

const CloseMerchentModal = (props) => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const { modalOpened, setModalOpened } = props;
  const [isSubmittingForm, setIsSubmittingForm] = React.useState(false);
  const {
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        reason: Yup.string().required(
          t('closeAccountForm.reason.errors.required')
        ),
        duration: Yup.string().required(
          t('closeAccountForm.duration.errors.required')
        )
      })
    ),
    mode: 'all',
    defaultValues: {
      reason: '',
      duration: ''
    }
  });

  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    setIsSubmittingForm(true);
    const formData = new FormData();
    formData.append('reason', data?.reason);
    formData.append('duration', data?.duration);
    customApiRequest(
      closeMerchentAccountApi(formData, user?.token, i18n.language),
      (res) => {
        setIsSubmittingForm(false);
        if (checkRes(res)) {
          setModalOpened(false);
          successNotification({
            title: t('successTitle'),
            message: 'تم غلق الحساب بنجاح'
          });
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      },
      (error) => {
        setIsSubmittingForm(false);
        errorNotification({
          title: t('errorTitle'),
          message: error?.response?.data?.message || 'حاول فى وقت لاحق'
        });
      }
    );
  };

  const [form] = Form.useForm();

  return (
    <Modal
      transitionName=""
      className="close-account-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '694px' }}
      title={
        <div className="modal-title">
          {i18n.language === 'ar' ? 'غلق الحساب' : 'Close My Bussiness'}
        </div>
      }
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
      }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      <h2>{i18n.language === 'ar' ? 'غلق الحساب' : 'Close account'}</h2>
      <p
        style={{
          lineHeight: '1.8',
          marginTop: 18,
          marginBottom: 14
        }}
      >
        {i18n.language === 'ar'
          ? 'إن تقدمك بإغلاق الحساب الإلكترونى لدى نعوم، يستوجب الرجوع للاتفاقية الموقعة من قبل الطرف الأول ويملثة نعوم مع الطرف الثاني ويمثلة التاجر، لتطبيق بندوها بشكل كامل، حفظا لحقوق الأطراف( التاجر ونعوم والعميل)، حال وجود حقوق اى طرف ، فأنه سيحصل عليها بموجب الاتفاقية الموقعة، كما يستوجب على الطرف الثاني التاجر، وضع المتاجر الإلكترونية المنشأة من قبلة لدى نعوم من خلال لوحة التحكم لدية في وضع (إيقاف عمل المتجر)، وكذلك الخدمات في وضع (إخفاء)، حتى لا يتم استلام أي طلبات جديدة من العملاء حتى الإنتهاء من الاتفاقية ليتم غلق الحساب نهائيا.'
          : 'To submit to close the electronic account with NAOMY, it is necessary to refer to the agreement signed by the first party, represented by NAOMY with the second party and the representative of the merchant, to implement its terms in full, to preserve the rights of the parties. (The Merchant, NAOMY and the Customer), if there are any rights of any party, he will obtain them under the Agreement. The second party, the merchant, also requires that the electronic stores established by NAOMY through its control panel be in the (stopping) mode, as well as the services in the (hide) mode, so that there are no new orders. Received from clients until the completion of the agreement to permanently close the account'}
      </p>

      <Form
        className="close-account-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className="text-area-label-wrap">
            <p className="label-p">{t('closeAccountForm.reason.label')}</p>
            <div className="text-field-wrap">
              <AntdTextarea
                className="form-text-area white-bg"
                name="reason"
                rows={4}
                placeholder={t('closeAccountForm.reason.label')}
                errorMsg={errors?.reason?.message}
                validateStatus={errors?.reason ? 'error' : ''}
                control={control}
              />
            </div>
          </div>

          <AntdTextField
            className="code-input-field"
            name="duration"
            type="text"
            placeholder={t('closeAccountForm.duration.label')}
            label={t('closeAccountForm.duration.label')}
            errorMsg={errors?.duration?.message}
            validateStatus={errors?.duration ? 'error' : ''}
            prefix={<UserIcon />}
            control={control}
          />
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isSubmittingForm}
          >
            {i18n.language === 'ar' ? 'حفظ' : 'Save'}
          </Button>
        </div>
      </Form>

      {isSubmittingForm && <LoadingModal />}
    </Modal>
  );
};

export default CloseMerchentModal;
