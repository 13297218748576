import axios from 'axios';

const getAllAgentsApi = async (paramsFilter, token, langIsoCode) => {
  try {
    const url = `Agent/getMyAgents?store_id=${
      paramsFilter?.store_id || ''
    }&product_id=${paramsFilter?.product_id || ''}&page=${
      paramsFilter?.page || ''
    }`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        lang: langIsoCode
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllAgentsApi;
