/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { PercentageOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import createNewProductApi from '../../../apis/products-apis/createNewProductApi';
import cameraImg from '../../../assets/imgs/icons/camera-icon.png';
import infoIcon from '../../../assets/imgs/icons/info-icon.svg';
import uploadImg from '../../../assets/imgs/icons/upload-icon.png';
import AntdCheckbox from '../../../common/antd-form-components/AntdCheckbox';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import CustomAntdDatePicker from '../../../common/antd-form-components/CustomAntdDatePicker';
import FileInput from '../../../common/file-input/FileInput';
import RadioButtonEmpty from '../../../common/icons/RadioButtonEmpty';
import RadioButtonFilled from '../../../common/icons/RadioButtonFilled';
import UserIcon from '../../../common/icons/UserIcon';
import generalRouterLinks from '../../../components/app/general-routes/gerneralRouterLinks';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import discountCodeTypes, {
  discountTypesOptions
} from '../../../const-values/discountCodeTypes';
import { isServiceInStore } from '../../../const-values/productSettingsOptions';
import refundOptions from '../../../const-values/refundOptions';
import CatsContext from '../../../contexts/cats-context/CatsContext';
import FilterCatsContext from '../../../contexts/filter-cats/FilterCatsContext';
import SingleProductContext from '../../../contexts/single-product-context/SingleProductContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import './NewProductForm.scss';
import newProductFormDefaultValues from './newProductFormDefaultValues';
import getProductFormSchema from './newProductFormValidationSchema';
// import useFirebaseDeviceToken from '../../custom-hooks/useFirebaseDeviceToken';

const baseLabelTransString = (t, key) => t(`product_form.${key}.label`);

const NewProductForm = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const {
    isSubmittingForm,
    setIsSubmittingForm,
    setIsLoading,
    fetchedSingleProductDetails
  } = useContext(SingleProductContext);
  const params = useParams();
  const { user } = useContext(UserContext);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    register,
    unregister,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(getProductFormSchema(fetchedSingleProductDetails, t)),
    mode: 'onSubmit',
    defaultValues: newProductFormDefaultValues
  });
  // React.useEffect(() => {
  //   if (watch('cat_id') && !params?.product_id) {
  //     setValue('catFilter_id', '');
  //   }
  // }, [watch('cat_id'), params?.product_id]);

  React.useEffect(() => {
    reset(newProductFormDefaultValues);
  }, [i18n.language]);

  // useEffect(() => {
  //   register('isSelectedServiceSetting');
  // }, []);

  const { fetchedData: allFetchedCats } = useContext(CatsContext);
  const { fetchedData: allFetchedFilterCats } = useContext(FilterCatsContext);
  const customApiRequest = useCustomApiRequest();

  // handle initial values
  useEffect(() => {
    if (fetchedSingleProductDetails) {
      setValue('name_ar', fetchedSingleProductDetails.name_ar || '');
      setValue('name_en', fetchedSingleProductDetails.name_en || '');
      setValue('phone', fetchedSingleProductDetails.phone);
      if (fetchedSingleProductDetails.cat_id)
        setValue('cat_id', String(fetchedSingleProductDetails.cat_id));
      if (fetchedSingleProductDetails.catFilter_id)
        setValue(
          'catFilter_id',
          String(fetchedSingleProductDetails.catFilter_id)
        );
      setValue(
        'description_ar',
        fetchedSingleProductDetails.description_ar || ''
      );
      setValue(
        'description_en',
        fetchedSingleProductDetails.description_en || ''
      );
      setValue('price', fetchedSingleProductDetails.price);
      setValue('inStock', fetchedSingleProductDetails.inStock);
      setValue('refundOption', fetchedSingleProductDetails.refundOption);
      setValue('hasCode', fetchedSingleProductDetails.hasCode ? true : false);
      setValue(
        'codeType',
        fetchedSingleProductDetails?.codeType
          ? String(fetchedSingleProductDetails.codeType)
          : ''
      );
      if (
        fetchedSingleProductDetails?.codeType == discountCodeTypes?.fixedValue
      ) {
        setValue('fixedValue', fetchedSingleProductDetails?.codeValue || '');
      }
      if (
        fetchedSingleProductDetails?.codeType ==
        discountCodeTypes?.percentageValue
      ) {
        setValue(
          'percentageValue',
          fetchedSingleProductDetails?.codeValue || ''
        );
      }
      setValue('codeValue', fetchedSingleProductDetails.codeValue);
      if (fetchedSingleProductDetails?.hasCode) {
        if (fetchedSingleProductDetails?.codeStartDate) {
          setValue(
            'codeStartDate',
            moment(fetchedSingleProductDetails.codeStartDate, 'YYYY-MM-DD')
          );
        }
        if (fetchedSingleProductDetails?.codeEndDate) {
          setValue(
            'codeEndDate',
            moment(fetchedSingleProductDetails.codeEndDate, 'YYYY-MM-DD')
          );
        }
      }

      if (
        fetchedSingleProductDetails?.isServiceInStore ==
        isServiceInStore?.checked
      ) {
        setValue('inStore.checked', true);
      } else {
        setValue('inStore.checked', false);
      }
      if (fetchedSingleProductDetails?.inStorePrice) {
        setValue('inStore.price', fetchedSingleProductDetails.inStorePrice);
      }

      if (fetchedSingleProductDetails?.inHomePrice) {
        setValue('insideCity.checked', true);
        setValue('insideCity.price', fetchedSingleProductDetails.inHomePrice);
      }
      if (fetchedSingleProductDetails?.OutSidePrice) {
        setValue('outsideCity.checked', true);
        setValue('outsideCity.price', fetchedSingleProductDetails.OutSidePrice);
      }
      if (fetchedSingleProductDetails?.outCountryPrice) {
        setValue('anotherCountry.checked', true);
        setValue(
          'anotherCountry.price',
          fetchedSingleProductDetails.outCountryPrice
        );
      }
    }
  }, [fetchedSingleProductDetails, i18n.language]);

  const onSubmit = (data) => {
    clearErrors('isSelectedServiceSetting');
    const formData = new FormData();
    formData.append('store_id', params?.store_id);
    formData.append('name_ar', data.name_ar);
    formData.append('name_en', data.name_en);
    formData.append('catFilter_id', data.catFilter_id);
    formData.append('cat_id', data.cat_id);
    if (fetchedSingleProductDetails?.price)
      formData.append('price', fetchedSingleProductDetails.price);
    else formData.append('price', data.price);
    formData.append('inStock', data.inStock);
    formData.append('refundOption', data.refundOption);
    if (data.hasCode) formData.append('hasCode', 1);
    if (data.codeType) formData.append('codeType', data.codeType);
    if (data.hasCode && data.codeAttach?.length > 0) {
      formData.append('codeAttach', data.codeAttach[0]);
    }
    if (data?.hasCode) {
      if (data?.codeStartDate) {
        formData.append(
          'codeStartDate',
          moment(data.codeStartDate).format('YYYY-MM-DD')
        );
      }
      if (data?.codeEndDate) {
        formData.append(
          'codeEndDate',
          moment(data.codeEndDate).format('YYYY-MM-DD')
        );
      }
    }
    if (data.hasCode && data.codeType) {
      if (data?.codeType == discountCodeTypes?.fixedValue)
        formData.append('codeValue', data.fixedValue);
      else if (data?.codeType == discountCodeTypes?.percentageValue)
        formData.append('codeValue', data.percentageValue);
    }
    if (data?.inStore?.checked) {
      formData.append('isServiceInStore', isServiceInStore?.checked);
      formData.append('inStorePrice', 0);
    } else {
      formData.append('isServiceInStore', isServiceInStore?.notChecked);
    }
    if (data?.insideCity?.checked && data?.insideCity?.price) {
      formData.append('inHomePrice', data?.insideCity?.price);
    }
    if (data?.outsideCity?.checked && data?.outsideCity?.price) {
      formData.append('OutSidePrice', data?.outsideCity?.price);
    }
    if (data?.anotherCountry?.checked && data?.anotherCountry?.price) {
      formData.append('outCountryPrice', data?.anotherCountry?.price);
    }
    formData.append('description_ar', data.description_ar);
    formData.append('description_en', data.description_en);
    if (data?.productImages?.length > 0)
      for (let ele of data.productImages)
        formData.append('productImages[]', ele);

    if (data.logo?.length > 0) formData.append('logo', data.logo[0]);
    if (data.video?.length > 0) formData.append('video', data.video[0]);
    setIsSubmittingForm(true);
    setIsLoading(true);
    if (!fetchedSingleProductDetails) {
      customApiRequest(
        createNewProductApi(formData, user?.token, i18n.language, false),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (checkRes(res)) {
            navigate(merchentRouterLinks?.storeProducts(params?.store_id));
            successNotification({
              title: t('successTitle'),
              message: 'تمت اضافة المنتج بنجاح'
            });
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('errorTitle'),
            message: 'حاول فى وقت لاحق'
          });
        }
      );
    } else {
      formData.append('product_id', fetchedSingleProductDetails.id);
      customApiRequest(
        createNewProductApi(formData, user?.token, i18n.language, true),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (checkRes(res)) {
            navigate(merchentRouterLinks?.storeProducts(params?.store_id));
            successNotification({
              title: t('successTitle'),
              message: 'تمت تعديل المنتج بنجاح'
            });
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('errorTitle'),
            message: 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  const handleCancel = () => {
    reset({
      ...newProductFormDefaultValues
    });
  };

  //
  const renderCodeDiscountOptions = () => {
    return (
      <div className="codes-radios-inputs-wrapper">
        <div className="radios-wrap">
          {/* <p className="radios-group-title">إختار طريقة استرجاع المنتج</p> */}
          <div className="labels-wrap">
            {discountTypesOptions(t)?.length > 0 &&
              discountTypesOptions(t).map((obj, i) => (
                <label
                  key={i}
                  className={`radio-input-label ${
                    String(watch(`codeType`)) === String(obj.value)
                      ? 'selected'
                      : ''
                  }`}
                >
                  <input
                    type="radio"
                    value={obj.value}
                    {...register(`codeType`)}
                  />
                  {watch(`codeType`) == String(obj.value) ? (
                    <RadioButtonFilled />
                  ) : (
                    <RadioButtonEmpty />
                  )}
                  <span className="label-span">{obj.title}</span>
                </label>
              ))}
          </div>
          {errors?.codeType && (
            <p
              className="error-p"
              style={{
                marginTop: 6
              }}
            >
              {errors?.codeType?.message}
            </p>
          )}
        </div>

        {watch('codeType') == discountCodeTypes?.fixedValue && (
          <AntdTextField
            className="code-input-field"
            name="fixedValue"
            type="text"
            placeholder={baseLabelTransString(t, 'fixedValue')}
            label={baseLabelTransString(t, 'fixedValue')}
            errorMsg={errors?.fixedValue?.message}
            validateStatus={errors?.fixedValue ? 'error' : ''}
            prefix={t('currency.sar')}
            control={control}
          />
        )}
        {watch('codeType') == discountCodeTypes?.percentageValue && (
          <AntdTextField
            className="code-input-field"
            name="percentageValue"
            type="text"
            placeholder={baseLabelTransString(t, 'percentageValue')}
            label={baseLabelTransString(t, 'percentageValue')}
            errorMsg={errors?.percentageValue?.message}
            validateStatus={errors?.percentageValue ? 'error' : ''}
            prefix={<PercentageOutlined />}
            control={control}
          />
        )}

        <div className="start-end-dates-wrap">
          <label className="custom-date-input order-date-label">
            <span className="label-span">
              {baseLabelTransString(t, 'discountStartDate')}
            </span>
            <CustomAntdDatePicker
              disablePast
              control={control}
              name={`codeStartDate`}
              placeholder={baseLabelTransString(t, 'discountStartDate')}
              className="order-discount-picker"
              formClassName="create-product-form"
              errorMsg={errors?.codeStartDate && errors.codeStartDate.message}
            />
          </label>
          <label className="custom-date-input order-date-label">
            <span className="label-span">
              {baseLabelTransString(t, 'discountEndDate')}
            </span>
            <CustomAntdDatePicker
              disablePast
              control={control}
              name={`codeEndDate`}
              placeholder={baseLabelTransString(t, 'discountEndDate')}
              className="order-discount-picker"
              formClassName="create-product-form"
              errorMsg={errors?.codeEndDate && errors.codeEndDate.message}
            />
          </label>
        </div>
      </div>
    );
  };
  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="create-product-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={() => {
          if (
            watch('inStore.checked') ||
            watch('insideCity.checked') ||
            watch('outsideCity.checked') ||
            watch('anotherCountry.checked')
          ) {
            // clearErrors('isSelectedServiceSetting');
            handleSubmit(onSubmit)();
          } else {
            // setError('isSelectedServiceSetting', {
            //   type: 'custom',
            //   message: i18n.language === 'ar' ?
            //   "من فضلك اختار أليه تنفيذ الخدمة أولا"
            //   :
            //   "Please select at least one choice"
            // });
            errorNotification({
              title: t('errorTitle'),
              message:
                i18n.language === 'ar'
                  ? 'من فضلك اختار أليه تنفيذ الخدمة أولا'
                  : 'Please select at least one choice'
            });
          }
        }}
      >
        <div className="form-body">
          <div className="section-one">
            <div className="section-title">
              {baseLabelTransString(t, 'productInfo')}
            </div>
            <div className="sub-section">
              <div className="names-wrapper">
                <AntdTextField
                  name="name_ar"
                  type="text"
                  placeholder={baseLabelTransString(t, 'nameAr')}
                  label={baseLabelTransString(t, 'nameAr')}
                  errorMsg={errors?.name_ar?.message}
                  validateStatus={errors?.name_ar ? 'error' : ''}
                  prefix={<UserIcon />}
                  control={control}
                />
                <AntdTextField
                  name="name_en"
                  type="text"
                  placeholder={baseLabelTransString(t, 'nameEn')}
                  label={baseLabelTransString(t, 'nameEn')}
                  errorMsg={errors?.name_en?.message}
                  validateStatus={errors?.name_en ? 'error' : ''}
                  prefix={<UserIcon />}
                  control={control}
                />
              </div>
              <div className="select-label-wrap">
                <p className="label-p">{baseLabelTransString(t, 'cat_id')}</p>
                <div className="custom-select-wrap without-icon">
                  <AntdSelectOption
                    name={`cat_id`}
                    errorMsg={errors?.cat_id && errors.cat_id.message}
                    validateStatus={errors?.cat_id ? 'error' : ''}
                    control={control}
                    setValue={setValue}
                    placeholder={baseLabelTransString(t, 'cat_id')}
                    prefix={<UserIcon />}
                    options={
                      allFetchedCats?.length > 0 &&
                      allFetchedCats.map((obj) => ({
                        title: obj?.name,
                        value: String(obj?.id)
                      }))
                    }
                    showSearch={true}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    formClassName="create-product-form"
                  />
                </div>
              </div>

              <div className="select-label-wrap">
                <p className="label-p">
                  {baseLabelTransString(t, 'catFilter_id')}
                </p>
                <div className="custom-select-wrap without-icon">
                  <AntdSelectOption
                    name={`catFilter_id`}
                    errorMsg={
                      errors?.catFilter_id && errors.catFilter_id.message
                    }
                    validateStatus={errors?.catFilter_id ? 'error' : ''}
                    control={control}
                    setValue={setValue}
                    placeholder={baseLabelTransString(t, 'catFilter_id')}
                    prefix={<UserIcon />}
                    options={
                      allFetchedFilterCats?.length > 0 &&
                      allFetchedFilterCats.map((obj) => ({
                        title: obj?.name,
                        value: String(obj?.id)
                      }))
                    }
                    showSearch={true}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    formClassName="create-product-form"
                  />
                </div>
              </div>

              <div className="text-area-label-wrap">
                <p className="label-p">
                  {baseLabelTransString(t, 'descriptionAr')}
                </p>
                <div className="text-field-wrap">
                  <AntdTextarea
                    className="form-text-area white-bg"
                    name="description_ar"
                    rows={4}
                    placeholder={baseLabelTransString(t, 'descriptionAr')}
                    errorMsg={errors?.description_ar?.message}
                    validateStatus={errors?.description_ar ? 'error' : ''}
                    control={control}
                  />
                </div>
              </div>
              <div className="text-area-label-wrap">
                <p className="label-p">
                  {baseLabelTransString(t, 'descriptionEn')}
                </p>
                <div className="text-field-wrap">
                  <AntdTextarea
                    className="form-text-area white-bg"
                    name="description_en"
                    rows={4}
                    placeholder={baseLabelTransString(t, 'descriptionEn')}
                    errorMsg={errors?.description_en?.message}
                    validateStatus={errors?.description_en ? 'error' : ''}
                    control={control}
                  />
                </div>
              </div>

              <AntdTextField
                disabled={fetchedSingleProductDetails}
                name="price"
                type="text"
                placeholder={baseLabelTransString(t, 'price')}
                label={baseLabelTransString(t, 'price')}
                errorMsg={errors?.price?.message}
                validateStatus={errors?.price ? 'error' : ''}
                prefix={<UserIcon />}
                control={control}
              />

              <AntdTextField
                name="inStock"
                type="text"
                placeholder={baseLabelTransString(t, 'inStock')}
                label={baseLabelTransString(t, 'inStock')}
                errorMsg={errors?.inStock?.message}
                validateStatus={errors?.inStock ? 'error' : ''}
                prefix={<UserIcon />}
                control={control}
              />
            </div>
            {/* //////////////// */}
            <div className="section-title">
              {baseLabelTransString(t, 'productSettings')}
            </div>
            <div className="sub-section">
              <div className="checkbox-label-input">
                <AntdCheckbox
                  name="inStore.checked"
                  control={control}
                  label={baseLabelTransString(t, 'inStore')}
                  errorMsg={errors?.inStore?.checked?.message}
                  validateStatus={errors?.inStore?.checked ? 'error' : ''}
                />
                {watch('inStore.checked') && (
                  <AntdTextField
                    style={{ display: 'none' }}
                    name="inStore.price"
                    type="text"
                    placeholder={baseLabelTransString(t, 'settings')}
                    // label="السعر"
                    errorMsg={errors?.inStore?.price?.message}
                    validateStatus={errors?.inStore?.price ? 'error' : ''}
                    prefix={<UserIcon />}
                    control={control}
                  />
                )}
              </div>

              <div className="checkbox-label-input">
                <AntdCheckbox
                  name="insideCity.checked"
                  control={control}
                  label={baseLabelTransString(t, 'insideCity')}
                  errorMsg={errors?.insideCity?.checked?.message}
                  validateStatus={errors?.insideCity?.checked ? 'error' : ''}
                />
                {watch('insideCity.checked') && (
                  <AntdTextField
                    name="insideCity.price"
                    type="text"
                    placeholder={baseLabelTransString(t, 'settings')}
                    // label="السعر"
                    errorMsg={errors?.insideCity?.price?.message}
                    validateStatus={errors?.insideCity?.price ? 'error' : ''}
                    prefix={<UserIcon />}
                    control={control}
                  />
                )}
              </div>

              <div className="checkbox-label-input">
                <AntdCheckbox
                  name="outsideCity.checked"
                  control={control}
                  label={baseLabelTransString(t, 'outsideCity')}
                  errorMsg={errors?.outsideCity?.checked?.message}
                  validateStatus={errors?.outsideCity?.checked ? 'error' : ''}
                />
                {watch('outsideCity.checked') && (
                  <AntdTextField
                    name="outsideCity.price"
                    type="text"
                    placeholder={baseLabelTransString(t, 'settings')}
                    // label="السعر"
                    errorMsg={errors?.outsideCity?.price?.message}
                    validateStatus={errors?.outsideCity?.price ? 'error' : ''}
                    prefix={<UserIcon />}
                    control={control}
                  />
                )}
              </div>

              <div className="checkbox-label-input">
                <AntdCheckbox
                  name="anotherCountry.checked"
                  control={control}
                  label={baseLabelTransString(t, 'anotherCountry')}
                  errorMsg={errors?.anotherCountry?.checked?.message}
                  validateStatus={
                    errors?.anotherCountry?.checked ? 'error' : ''
                  }
                />
                {watch('anotherCountry.checked') && (
                  <AntdTextField
                    name="anotherCountry.price"
                    type="text"
                    placeholder={baseLabelTransString(t, 'settings')}
                    // label="السعر"
                    errorMsg={errors?.anotherCountry?.price?.message}
                    validateStatus={
                      errors?.anotherCountry?.price ? 'error' : ''
                    }
                    prefix={<UserIcon />}
                    control={control}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="section-two">
            <div className="section-title">
              {baseLabelTransString(t, 'productImages')}
            </div>
            <div
              className="sub-section"
              style={{
                display: 'grid',
                gap: 14
              }}
            >
              <div className="images-wrap">
                <FileInput
                  disabled={
                    fetchedSingleProductDetails?.productImages?.length >= 0
                  }
                  name="productImages"
                  label=""
                  max={5}
                  // accept="image/png, image/jpg, image/jpeg, image/gif"
                  accept="image/*"
                  multiple={true}
                  setValue={setValue}
                  watch={watch}
                  register={register}
                  unregister={unregister}
                  // setUrls={setUrls}
                  dropzoneText={baseLabelTransString(t, 'productImages')}
                  className="product-images-dropzone"
                  dropzoneUrls={
                    fetchedSingleProductDetails?.productImages?.length > 0
                      ? fetchedSingleProductDetails.productImages.map((i) => ({
                          id: i.id,
                          url: i.image
                        }))
                      : []
                  }
                  canDelete={false}
                  icon={
                    <img src={uploadImg} style={{ width: 40 }} alt="upload" />
                  }
                  // disabled={
                  //   fetchedSingleProductDetails?.productImages?.length >= 5 &&
                  //   watch('productImages')?.length >= 1
                  //     ? true
                  //     : false
                  // }
                  showError={errors?.productImages}
                  errorMsg={errors?.productImages?.message}
                />
              </div>

              <div className="video-logo-wrap">
                <div className="logo-dropzone-wrap">
                  <FileInput
                    disabled={fetchedSingleProductDetails?.logo}
                    name="logo"
                    // accept="image/png, image/jpg, image/jpeg, image/gif"
                    label=""
                    accept="image/*"
                    multiple={false}
                    setValue={setValue}
                    watch={watch}
                    register={register}
                    unregister={unregister}
                    // setUrls={setUrls}
                    // dropzoneText="اسحب الصورة وضعها هنا ..."
                    icon={
                      <img
                        style={{
                          width: 52
                        }}
                        src={cameraImg}
                        alt="upload"
                      />
                    }
                    className="product-images-dropzone"
                    dropzoneUrls={
                      fetchedSingleProductDetails?.logo
                        ? [{ url: fetchedSingleProductDetails.logo }]
                        : []
                    }
                    canDelete={false}
                    showError={errors?.logo}
                    errorMsg={errors?.logo?.message}
                  />
                </div>

                <div className="video-dropzone-wrap">
                  <FileInput
                    disabled={fetchedSingleProductDetails?.video}
                    name="video"
                    label=""
                    // accept="image/png, image/jpg, image/jpeg, image/gif"
                    max={1}
                    accept="video/*"
                    multiple={false}
                    setValue={setValue}
                    watch={watch}
                    register={register}
                    unregister={unregister}
                    // setUrls={setUrls}
                    dropzoneText={baseLabelTransString(t, 'video')}
                    className="product-images-dropzone"
                    dropzoneUrls={
                      fetchedSingleProductDetails?.video
                        ? [{ url: fetchedSingleProductDetails.video }]
                        : []
                    }
                    canDelete={false}
                    icon={
                      <img src={uploadImg} style={{ width: 40 }} alt="upload" />
                    }
                    showError={errors?.video}
                    errorMsg={errors?.video?.message}
                  />
                </div>
              </div>
            </div>

            <div className="section-title">
              {baseLabelTransString(t, 'refundOption')}
            </div>
            <div className="sub-section refund-section">
              <RouterLink to={generalRouterLinks?.merchantRefundTermsRoute}>
                <p
                  style={{
                    marginBottom: 12
                  }}
                >
                  <span>{t('product_form.refundTerms.mainTitle')} </span>
                  <span
                    style={{
                      color: '#FB2E86'
                    }}
                  >
                    {t('product_form.refundTerms.learnMore')}{' '}
                  </span>
                  <img src={infoIcon} alt="info" />
                </p>
              </RouterLink>
              <div className="radios-wrap">
                {/* <p className="radios-group-title">إختار طريقة استرجاع المنتج</p> */}
                <div
                  className="labels-wrap"
                  style={{
                    display: 'grid',
                    gridAutoFlow: 'row'
                  }}
                >
                  {refundOptions(t)?.length > 0 &&
                    refundOptions(t).map((obj, i) => (
                      <label
                        key={i}
                        className={`radio-input-label ${
                          String(watch(`refundOption`)) === String(obj.value)
                            ? 'selected'
                            : ''
                        }`}
                      >
                        <input
                          type="radio"
                          value={obj.value}
                          {...register(`refundOption`)}
                        />
                        {watch(`refundOption`) == String(obj.value) ? (
                          <RadioButtonFilled />
                        ) : (
                          <RadioButtonEmpty />
                        )}
                        <span className="label-span">{obj.title}</span>
                      </label>
                    ))}
                </div>
                {errors?.refundOption && (
                  <p
                    className="error-p"
                    style={{
                      marginTop: 6
                    }}
                  >
                    {errors?.refundOption?.message}
                  </p>
                )}
              </div>
            </div>

            <div className="section-title">
              {baseLabelTransString(t, 'discount')}
            </div>

            <div
              className="sub-section"
              style={{
                paddingBottom: 0
              }}
            >
              <AntdCheckbox
                name="hasCode"
                control={control}
                label={baseLabelTransString(t, 'hasCode')}
                errorMsg={errors?.hasCode?.message}
                validateStatus={errors?.hasCode ? 'error' : ''}
              />
              {watch('hasCode') ? (
                <div className="discount-fields-wrapper">
                  {renderCodeDiscountOptions()}

                  <div
                    className="images-wrap discount-images-wrap"
                    style={{
                      paddingBottom: 22
                    }}
                  >
                    <FileInput
                      // disabled={fetchedSingleProductDetails?.codeAttach}
                      name="codeAttach"
                      label=""
                      max={5}
                      // accept="image/png, image/jpg, image/jpeg, image/gif"
                      accept="image/*, application/*"
                      multiple={false}
                      setValue={setValue}
                      watch={watch}
                      register={register}
                      unregister={unregister}
                      icon={
                        <img
                          src={uploadImg}
                          style={{ width: 40 }}
                          alt="upload"
                        />
                      }
                      // setUrls={setUrls}
                      dropzoneText={baseLabelTransString(t, 'codeAttach')}
                      className="product-images-dropzone"
                      dropzoneUrls={
                        fetchedSingleProductDetails?.codeAttach
                          ? [{ url: fetchedSingleProductDetails.codeAttach }]
                          : []
                      }
                      canDelete={false}
                      showError={errors?.codeAttach}
                      errorMsg={errors?.codeAttach?.message || '---'}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="yes-no-btns-wrapper">
            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              // icon={<LoginOutlined />}
              loading={isSubmittingForm}
            >
              {!isSubmittingForm
                ? baseLabelTransString(t, 'submitBtn')
                : t('product_form.submitBtn.submitting')}
            </Button>
            <Button onClick={handleCancel} className="cancel-btn">
              {baseLabelTransString(t, 'cancelBtn')}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default NewProductForm;
