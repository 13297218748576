import deleteImg from '../../../assets/imgs/icons/trash.png';
import editImg from '../../../assets/imgs/icons/edit.png';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import userRouterLinks from '../../../components/app/user-routes/uesRouterLinks';
import { useState } from 'react';
import MobileIcon from '../../../common/icons/MobileIcon';
import { Popconfirm } from 'antd';
import deleteSingleAddressApi from '../../../apis/my-addresses-apis/deleteAddressApi';
import { useContext } from 'react';
import UserContext from '../../../contexts/user-context/UserProvider';
import './MyAddressCard.scss';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import LoadingModal from '../../../common/loading-modal/LoadingModal';

const MyAddressCard = ({ card, setFetchAddressesCount }) => {
  const { t, i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const customApiRequest = useCustomApiRequest();
  const handleDeleteAddress = () => {
    setIsSubmittingForm(true);
    customApiRequest(
      deleteSingleAddressApi(
        {
          addressId: card?.id
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        setIsSubmittingForm(false);
        if (checkRes(res)) {
          successNotification({
            title: t('successTitle'),
            message: 'تم حذف العنوان بنجاح'
          });
          setFetchAddressesCount((prev) => prev + 1);
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message ? res.data.message : 'حاول فى وقت لاحق'
          });
        }
      },
      (error) => {
        setIsSubmittingForm(false);
        errorNotification({
          title: t('errorTitle'),
          message: 'حاول فى وقت لاحق'
        });
      }
    );
  };

  return (
    <div className="my-address-card">
      <p className="card-name">
        {i18n?.language === 'ar' ? card.address_ar : card.address_en}
      </p>

      <div className="c-c-wrap">
        {card?.country && <p className="card-country">{card.country}</p>}
        {card?.country && card?.city && ' - '}
        {card?.city && <p className="card-city">{card.city}</p>}
      </div>
      {card?.area && <p className="card-area">{card.area}</p>}
      {card?.streetName && <p className="card-streetName">{card.streetName}</p>}
      {card?.phone && (
        <p className="card-phone">
          <MobileIcon color="#6D6D6D" />
          {card.phone}
        </p>
      )}

      <div className="edit-delete-btns">
        <RouterLink
          to={userRouterLinks?.editAddressRoute(card?.id)}
          className="edit-btn"
        >
          <img src={editImg} alt="edit" />
          {t('addressesPage.edit')}
        </RouterLink>

        <Popconfirm
          title={t('addressesPage.confirm')}
          onConfirm={(key) => handleDeleteAddress()}
          okText={t('addressesPage.yes')}
          cancelText={t('addressesPage.no')}
        >
          <button className="delete-btn">
            <img src={deleteImg} alt="delete" />
            {t('addressesPage.delete')}
          </button>
        </Popconfirm>
      </div>

      {isSubmittingForm && <LoadingModal />}
    </div>
  );
};

export default MyAddressCard;
