/* eslint-disable eqeqeq */
import OrderSummery from './OrderSummery';
import SelectAddressWrap from './SelectAddressWrap';
import './CartStepTwo.scss';
import PaymentSection from './PaymentSection';
import { useContext } from 'react';
import CartContext from '../../contexts/cart-context/CartContext';
import { t } from 'i18next';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import makeOrderApi from '../../apis/cart-apis/makeOrderApi';
import UserContext from '../../contexts/user-context/UserProvider';
import { useTranslation } from 'react-i18next';
import checkRes from '../../utils/checkRes';
import errorNotification from '../../utils/errorNotification';
import successNotification from '../../utils/successNotification';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import CustomStepperWrapContext from '../../contexts/custom-stepper-wrap-context/CustomStepperWrapContext';
import DocTitleScrollTop from '../../utils/DocTitleScrollTop';
import { paymentTypesValues } from '../../const-values/paymentTypes';

const CartStepTwo = () => {
  DocTitleScrollTop('My Cart');
  const { user, setFetchUserCount } = useContext(UserContext);
  const { i18n } = useTranslation();
  const {
    isSubmittingOrder,
    setIsSubmittingOrder,
    cartProducts,
    selectedAddress,
    paymentType,
    setCartProducts,
    discountCodeId,
    setDiscountCodeId,
    setDiscountCode,
    setDiscountType,
    setDiscountValue,
    setDiscountStoreId,
    setCartOrderRes,
    total
  } = useContext(CartContext);
  const { setStep } = useContext(CustomStepperWrapContext);
  const customApiRequest = useCustomApiRequest();

  const handleSubmitTotal = () => {
    setIsSubmittingOrder(true);
    const formData = new FormData();

    if (selectedAddress) formData.append('address_id', selectedAddress.id);
    if (paymentType) formData.append('paymentType', paymentType);
    if (cartProducts?.length > 0) {
      const mappedProducts = cartProducts.map((p) => ({
        id: p?.id,
        quantity: p?.quantity
      }));
      formData.append('cart', JSON.stringify(mappedProducts));
    }
    if (discountCodeId) formData.append('code_id', discountCodeId);
    customApiRequest(
      makeOrderApi(formData, user?.token, i18n.language),

      (res) => {
        setIsSubmittingOrder(false);
        if (checkRes(res)) {
          setCartOrderRes(res?.data?.data);
          setFetchUserCount((prev) => prev + 1);
          successNotification({
            title: t('successTitle'),
            message: res?.data?.message || t('successMessage')
          });
          // navigate(routerLinks?.homePage);
          setStep((prev) => prev + 1);
          setCartProducts([]);
          if (discountCodeId) {
            setDiscountCodeId('');
            setDiscountCode('');
            setDiscountType('');
            setDiscountValue('');
            setDiscountStoreId('');
          }
          window.scrollTo(0, 0);
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || 'Try again later'
          });
        }
      },
      (error) => {
        setIsSubmittingOrder(false);
        errorNotification({
          title: t('errorTitle'),
          message: error?.response?.data?.message || 'Try again later'
        });
      }
    );
  };

  const handleMakeOrder = () => {
    if (!paymentType) {
      errorNotification({
        title: t('errorTitle'),
        message:
          i18n?.language === 'ar'
            ? 'من فضلك اختار طريقة الدفع'
            : 'Select payment type'
      });
    } else if (
      paymentType == paymentTypesValues?.walletPayment
      //  || paymentType == paymentTypesValues?.cashPayment
    ) {
      if (parseFloat(user.wallet) >= parseFloat(total)) {
        handleSubmitTotal();
      } else {
        errorNotification({
          title: t('errorTitle'),
          message:
            i18n.language === 'ar'
              ? 'المبلغ غير متوفر في المحفظه'
              : 'Total is not valid in your wallet'
        });
      }
    } else {
      handleSubmitTotal();
    }
  };

  return (
    <div className="stepper-step cart-step-two">
      <div className="addressess-summery-wrap">
        <div className="add-payment-wrap">
          <SelectAddressWrap />
          <PaymentSection />
          <div className="btns-wrapper">
            <button className="checkout-btn" onClick={handleMakeOrder}>
              {t('checkoutForm.paymentBtn')}
            </button>
            <button
              className="back-btn"
              onClick={() => {
                setStep((prev) => prev - 1);
              }}
            >
              {t('checkoutForm.backPrevStep')}
            </button>
          </div>
        </div>
        <OrderSummery />
      </div>

      {isSubmittingOrder && <LoadingModal />}
    </div>
  );
};

export default CartStepTwo;
