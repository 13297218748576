/* eslint-disable eqeqeq */
import CustomImage from '../../../common/custom-image/CustomImage';
import shareImg from '../../../assets/imgs/icons/lefted-cart/share.svg';

const leftedCartProductsTableColumns = (
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  i18n,
  t
) => {
  // const handleViewDelegateDetails = (record) => {
  //   const found = allFetchedTableData.find((obj) => obj.id === record.id);
  //   if (found) {
  //     setSelectedTableRow({ ...found });
  //     setDetailsModalOpened(true);
  //   }
  // };
  const sharedT = (key) => t(`leftedCartPage.${key}`);

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap lefted-cart-row-btns">
        <button
          className="row-btn"
          onClick={() => {
            setSelectedTableRow(record);
            setFormModalOpened(true);
          }}
        >
          <img src={shareImg} alt="Share" />
        </button>
      </div>
    );
  };

  return [
    {
      title: sharedT('products'),
      dataIndex: 'product',
      render: (_, record) => {
        return (
          <div className="img-name-wrap">
            <CustomImage className="product-img" src={record?.product?.logo} />
            <p className="pro-name">{record?.product?.name}</p>
          </div>
        );
      }
    },
    {
      title: sharedT('price'),
      dataIndex: 'price',
      render: (_, record) => {
        return record?.product?.price ? record.product?.price : '---';
      }
    },
    {
      title: sharedT('quantity'),
      dataIndex: 'quantity',
      render: (_, record) => {
        return record?.quantity;
      }
    },
    {
      title: sharedT('total'),
      dataIndex: 'total',
      render: (_, record) => {
        return record?.totalPrice + ' ' + t('currency.sar');
      }
    },

    {
      title: sharedT('action'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }

    // {
    //   title: 'عرض التفاصيل',
    //   dataIndex: 'details',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <Tooltip title="عرض التفاصيل">
    //         <Button
    //           size="large"
    //           type="dashed"
    //           shape="round"
    //           icon={<EyeOutlined />}
    //           onClick={() => handleViewDelegateDetails(record)}
    //         />
    //       </Tooltip>
    //     );
    //   }
    // }
  ];
};

export default leftedCartProductsTableColumns;
