/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useSelectedPage from '../../custom-hooks/useSelectedPage';
import appPages from '../../const-values/appPages';
import UserContext from '../../contexts/user-context/UserProvider';
import './HomePage.scss';
import HomeSlider from './HomeSlider';
import HomeCategorySection from './HomeCategorySection';
import TrendeyProductsSlider from './home-slides/TrendyProductsSlider';
import NewProductsSlider from './home-slides/NewProductsSlider';
import OffersSection from './OfferSection';
import MenSlider from './home-slides/MenSlider';
import WomenSlider from './home-slides/WomenSlider';
import SuccessCard from '../../components/success-card/SuccessCard';
import SubscriptionSection from './subscription-section/SubscriptionSection';
import BlogsSlider from './home-slides/BlogsSlider';
import SharedAppContext from '../../contexts/shared-app-context/SharedAppContext';
import productStatus from '../../const-values/productStatus';
import storeStatus from '../../const-values/storeStatus';

const HomePage = () => {
  const { i18n, t } = useTranslation();
  const { user, setCurrentUser } = useContext(UserContext);

  const { homePage } = appPages(i18n, t, user);
  useSelectedPage({
    currentPage: homePage
  });

  const { isLoadingHomeData, allFetchedHomeData, setFetchHomeCount } =
    useContext(SharedAppContext);

  const sharedSuccessT = (key) => t(`successSignup.${key}`);

  return (
    <div className="home-page">
      {user?.firstTime ? (
        <div className="custom-container">
          <div className="success-card-container">
            {/* <Logo /> */}
            <SuccessCard
              mainTitle={sharedSuccessT('mainTitle')}
              subTitle={sharedSuccessT('subTitle')}
              btnText={sharedSuccessT('btnText')}
              // btnUrl={routerLinks?.homePage}
              onClick={() => {
                setCurrentUser({
                  ...user,
                  firstTime: false
                });
              }}
            />
            <p className="copyright-p">{t('copyRights')}</p>
          </div>
        </div>
      ) : (
        <>
          <HomeSlider />
          <HomeCategorySection />
          {allFetchedHomeData && (
            <>
              {allFetchedHomeData?.trending?.length > 0 && (
                <TrendeyProductsSlider
                  isLoading={isLoadingHomeData}
                  slides={allFetchedHomeData?.trending
                    .filter((obj) => obj?.status === productStatus?.active)
                    .filter((obj) => obj?.storeStatus === storeStatus?.active)}
                  setFetchCount={setFetchHomeCount}
                />
              )}
              {allFetchedHomeData?.new?.length > 0 && (
                <NewProductsSlider
                  isLoading={isLoadingHomeData}
                  slides={allFetchedHomeData?.new
                    .filter((obj) => obj?.status === productStatus?.active)
                    .filter((obj) => obj?.storeStatus === storeStatus?.active)}
                  setFetchCount={setFetchHomeCount}
                />
              )}
              {/* {allFetchedHomeData?.topDiscount?.length > 0 && (
                <TopDiscountsSlider
                  isLoading={isLoadingHomeData}
                  slides={allFetchedHomeData?.topDiscount}
                  setFetchCount={setFetchHomeCount}
                />
              )} */}
            </>
          )}
          <OffersSection />

          <WomenSlider />

          <MenSlider />

          <BlogsSlider
            isLoading={isLoadingHomeData}
            slides={allFetchedHomeData?.blog}
            setFetchCount={setFetchHomeCount}
          />

          <SubscriptionSection />
        </>
      )}
    </div>
  );
};

export default HomePage;
