import { useState, createContext } from 'react';
import { orderStatusValues } from '../../const-values/orderStatus';

const INITIAL_VALUES = {
  isLoadingOrders: false,
  setIsLoadingOrders: (v) => {},
  isRating: false,
  setIsRating: (v) => {},
  fetchOrdersCount: 0,
  setFetchOrdersCount: (v) => {},
  allFetchedOrders: null,
  setAllFetchedOrders: (v) => {},
  isRejecting: false,
  setIsRejecting: (v) => {},
  confirmFormOpened: false,
  setConfirmFormOpened: (v) => {},
  isAccepting: false,
  setIsAccepting: (v) => {},
  receiptModalOpened: false,
  reportClientModalOpened: false,
  setReportClientModalOpened: (v) => {},
  isReporting: false,
  setIsReporting: (v) => {},
  setReceiptModalOpened: (v) => {},
  addressMapModalOpened: false,
  setAddressMapModalOpened: (v) => {},
  selectedOrder: null,
  setSelectedOrder: (v) => {},
  //
  //
  ordersPagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setOrdersPagination: (v) => {},
  // filter
  ordersFilter: {
    isOrder: orderStatusValues?.all,
    page: 1
  },
  setOrdersFilter: (v) => {}
};

const StoreOrdersContext = createContext(INITIAL_VALUES);

export const StoreOrdersProvider = ({ children }) => {
  const [isLoadingOrders, setIsLoadingOrders] = useState(
    INITIAL_VALUES.isLoadingOrders
  );
  const [isRating, setIsRating] = useState(INITIAL_VALUES.isRating);
  const [isRejecting, setIsRejecting] = useState(INITIAL_VALUES.isRejecting);
  const [isAccepting, setIsAccepting] = useState(INITIAL_VALUES.isAccepting);
  const [confirmFormOpened, setConfirmFormOpened] = useState(
    INITIAL_VALUES.isRejecting
  );
  const [reportClientModalOpened, setReportClientModalOpened] = useState(
    INITIAL_VALUES?.reportClientModalOpened
  );
  const [isReporting, setIsReporting] = useState(INITIAL_VALUES?.isReporting);
  const [receiptModalOpened, setReceiptModalOpened] = useState(
    INITIAL_VALUES?.receiptModalOpened
  );
  const [addressMapModalOpened, setAddressMapModalOpened] = useState(
    INITIAL_VALUES?.addressMapModalOpened
  );
  const [selectedOrder, setSelectedOrder] = useState(
    INITIAL_VALUES?.selectedOrder
  );
  const [fetchOrdersCount, setFetchOrdersCount] = useState(
    INITIAL_VALUES.fetchOrdersCount
  );
  const [allFetchedOrders, setAllFetchedOrders] = useState(
    INITIAL_VALUES.allFetchedOrders
  );

  //
  const [ordersFilter, setOrdersFilter] = useState(
    INITIAL_VALUES?.ordersFilter
  );
  const [ordersPagination, setOrdersPagination] = useState(
    INITIAL_VALUES?.ordersPagination
  );

  return (
    <StoreOrdersContext.Provider
      value={{
        isLoadingOrders,
        setIsLoadingOrders,
        isRating,
        setIsRating,
        selectedOrder,
        setSelectedOrder,
        reportClientModalOpened,
        setReportClientModalOpened,
        isReporting,
        setIsReporting,
        isRejecting,
        setIsRejecting,
        confirmFormOpened,
        setConfirmFormOpened,
        isAccepting,
        setIsAccepting,
        addressMapModalOpened,
        setAddressMapModalOpened,
        receiptModalOpened,
        setReceiptModalOpened,
        fetchOrdersCount,
        setFetchOrdersCount,
        allFetchedOrders,
        setAllFetchedOrders,
        ordersFilter,
        setOrdersFilter,
        ordersPagination,
        setOrdersPagination
      }}
    >
      {children}
    </StoreOrdersContext.Provider>
  );
};

export default StoreOrdersContext;
