/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import sendOfferToLeftedCartApi from '../../../apis/stores-apis/sendOfferToLeftedCartApi';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import CustomAntdDatePicker from '../../../common/antd-form-components/CustomAntdDatePicker';
import DollarSignIcon from '../../../common/icons/DollarSignIcon';
import PercentageIcon from '../../../common/icons/PercentageIcon';
import RadioButtonEmpty from '../../../common/icons/RadioButtonEmpty';
import RadioButtonFilled from '../../../common/icons/RadioButtonFilled';
import SendOfferIcon from '../../../common/icons/SendOfferIcon';
import LoadingModal from '../../../common/loading-modal/LoadingModal';
import {
  notificationTypes,
  offerTypes
} from '../../../const-values/leftedCartOptions';
import StoreLeftedCartContext from '../../../contexts/store-lefted-cart-context/StoreLeftedCartContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import './LeftedCartOfferForm.scss';
import leftedCartValidationSchema from './leftedCartValidationSchema';

const LeftedCartOfferForm = ({ page = false }) => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const {
    isSubmittingForm,
    setIsSubmittingForm,
    setFetchCount,
    selectedTableRow,
    setFormModalOpened
  } = useContext(StoreLeftedCartContext);
  const sharedTransLabel = (key) => t(`leftedCartForm.${key}.label`);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors }
  } = useForm({
    resolver: leftedCartValidationSchema({
      t,
      selectedTableRow
    }),
    mode: 'all',
    defaultValues: {
      offerType: '',
      staticValue: '',
      percentageValue: '',
      notificationType: '',
      // startDate: '',
      offerEndDate: ''
    }
  });

  // console.log('watch :, ', watch());
  // console.log('errors : ', errors);

  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      setValue(
        'offerType',
        selectedTableRow?.offerType ? String(selectedTableRow?.offerType) : ''
      );
      if (selectedTableRow?.offerType == offerTypes?.static)
        setValue('staticValue', String(selectedTableRow?.offerValue));
      else if (selectedTableRow?.offerType == offerTypes?.percentage)
        setValue('percentageValue', String(selectedTableRow?.offerValue));
      if (selectedTableRow?.notificationType)
        setValue(
          'notificationType',
          String(selectedTableRow?.notificationType)
        );
      // if (selectedTableRow?.startDate) {
      //   setValue(
      //     'startDate',
      //     moment(selectedTableRow.codeStartDate, 'YYYY-MM-DD')
      //   );
      // }
      if (selectedTableRow?.offerEndDate) {
        setValue(
          'offerEndDate',
          moment(selectedTableRow.offerEndDate, 'YYYY-MM-DD')
        );
      }
    }
  }, [selectedTableRow]);

  const customApiRequest = useCustomApiRequest();

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('cart_id', selectedTableRow.id);
    if (data.offerType) formData.append('offerType', data.offerType);
    if (data.offerType == offerTypes?.static)
      formData.append('offerValue', data.staticValue);
    else if (data.offerType == offerTypes?.percentage)
      formData.append('offerValue', data.percentageValue);
    if (data.notificationType)
      formData.append('notificationType', data.notificationType);
    // if (data?.startDate) {
    //   formData.append('startDate', moment(data.startDate).format('YYYY-MM-DD'));
    // }
    if (data?.offerEndDate) {
      formData.append(
        'offerEndDate',
        moment(data.offerEndDate).format('YYYY-MM-DD')
      );
    }
    setIsSubmittingForm(true);
    // setIsLoadSingleProduct(true);
    customApiRequest(
      sendOfferToLeftedCartApi(formData, user?.token, i18n.language),
      (res) => {
        setIsSubmittingForm(false);
        // setIsLoadSingleProduct(false);
        if (checkRes(res)) {
          successNotification({
            title: t('successTitle'),
            message: res?.data?.message || 'تم ارسال العرض بنجاح'
          });
          setFetchCount((prev) => prev + 1);
          setFormModalOpened(false);
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      },
      (error) => {
        setIsSubmittingForm(false);
        // setIsLoadSingleProduct(false);
        errorNotification({
          title: t('errorTitle'),
          message: 'حاول فى وقت لاحق'
        });
      }
    );
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="lefted-cart-offer-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <h3>{selectedTableRow.product?.name}</h3>
          <div className="radios-wrap">
            <p className="radios-group-title">
              {sharedTransLabel('discountType')}
            </p>
            <div className="labels-wrap discount-label">
              <div className="radio-text-field-wrap">
                <label
                  className={`radio-input-label ${i18n.dir()} ${
                    String(watch(`offerType`)) == offerTypes?.static
                      ? 'selected'
                      : ''
                  }`}
                >
                  <input
                    type="radio"
                    value={offerTypes?.static}
                    {...register(`offerType`)}
                  />
                  {watch(`offerType`) == offerTypes?.static ? (
                    <RadioButtonFilled />
                  ) : (
                    <RadioButtonEmpty color="#C4C4C4" />
                  )}
                  <span className="label-span">
                    {sharedTransLabel('staticPrice')}
                  </span>
                </label>
                {watch('offerType') == offerTypes?.static && (
                  <AntdTextField
                    className="price-field"
                    name="staticValue"
                    type="text"
                    placeholder={t('leftedCartForm.price')}
                    label={t('leftedCartForm.price')}
                    errorMsg={errors?.staticValue?.message}
                    validateStatus={errors?.staticValue ? 'error' : ''}
                    prefix={<DollarSignIcon />}
                    control={control}
                  />
                )}
              </div>

              <div className="radio-text-field-wrap">
                <label
                  className={`radio-input-label ${i18n.dir()} ${
                    String(watch(`offerType`)) == offerTypes?.percentage
                      ? 'selected'
                      : ''
                  }`}
                >
                  <input
                    type="radio"
                    value={offerTypes?.percentage}
                    {...register(`offerType`)}
                  />
                  {watch(`offerType`) == offerTypes?.percentage ? (
                    <RadioButtonFilled />
                  ) : (
                    <RadioButtonEmpty color="#C4C4C4" />
                  )}
                  <span className="label-span">
                    {sharedTransLabel('percentagePrice')}
                  </span>
                </label>
                {watch('offerType') == offerTypes?.percentage && (
                  <AntdTextField
                    name="percentageValue"
                    className="price-field"
                    type="text"
                    placeholder={t('leftedCartForm.price')}
                    label={t('leftedCartForm.price')}
                    errorMsg={errors?.percentageValue?.message}
                    validateStatus={errors?.percentageValue ? 'error' : ''}
                    prefix={<PercentageIcon />}
                    control={control}
                  />
                )}
              </div>
            </div>
            {errors?.offerType && (
              <p
                className="error-p"
                style={{
                  padding: '0 18px'
                }}
              >
                {errors?.offerType?.message}
              </p>
            )}
          </div>
          {/*  */}
          <div className="radios-wrap notification-types-wrap">
            <p className="radios-group-title">
              {sharedTransLabel('notifications')}
            </p>
            <div className="labels-wrap discount-label">
              <label
                className={`radio-input-label ${i18n.dir()} ${
                  String(watch(`notificationType`)) == notificationTypes?.email
                    ? 'selected'
                    : ''
                }`}
              >
                <input
                  type="radio"
                  value={notificationTypes?.email}
                  {...register(`notificationType`)}
                />
                {watch(`notificationType`) == notificationTypes?.email ? (
                  <RadioButtonFilled />
                ) : (
                  <RadioButtonEmpty color="#C4C4C4" />
                )}
                <span className="label-span">
                  {t('leftedCartForm.notifications.emailType')}
                </span>
              </label>
              <label
                className={`radio-input-label ${i18n.dir()} ${
                  String(watch(`notificationType`)) ==
                  notificationTypes?.notification
                    ? 'selected'
                    : ''
                }`}
              >
                <input
                  type="radio"
                  value={notificationTypes?.notification}
                  {...register(`notificationType`)}
                />
                {watch(`notificationType`) ==
                notificationTypes?.notification ? (
                  <RadioButtonFilled />
                ) : (
                  <RadioButtonEmpty color="#C4C4C4" />
                )}
                <span className="label-span">
                  {t('leftedCartForm.notifications.notificationType')}
                </span>
              </label>
            </div>
            {errors?.notificationType && (
              <p
                className="error-p"
                style={{
                  padding: '0 18px'
                }}
              >
                {errors?.notificationType?.message}
              </p>
            )}
          </div>

          <div className="start-end-dates-wrap">
            {/* <label className="custom-date-input order-date-label">
              <span className="label-span">
                {t('leftedCartForm.startDate.label')}
              </span>
              <CustomAntdDatePicker
                disablePast
                control={control}
                name={`startDate`}
                placeholder={t('leftedCartForm.startDate.label')}
                className="order-discount-picker"
                formClassName="lefted-cart-offer-form"
                errorMsg={errors?.startDate && errors.startDate.message}
              />
            </label> */}

            <label className="custom-date-input order-date-label">
              <span className="label-span">
                {t('leftedCartForm.offerEndDate.label')}
              </span>
              <CustomAntdDatePicker
                disablePast
                control={control}
                name={`offerEndDate`}
                placeholder={t('leftedCartForm.offerEndDate.label')}
                className="order-discount-picker"
                formClassName="lefted-cart-offer-form"
                errorMsg={errors?.offerEndDate && errors.offerEndDate.message}
              />
            </label>
          </div>
        </div>

        <div className="form-actions-btns">
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            icon={!isSubmittingForm ? <SendOfferIcon /> : <LoadingOutlined />}
            loading={isSubmittingForm}
          >
            {isSubmittingForm
              ? t('leftedCartForm.sendOfferBtn.loading')
              : t('leftedCartForm.sendOfferBtn.label')}
          </Button>
          {!page && (
            <Button
              onClick={() => setFormModalOpened(false)}
              className="cancel-btn"
              htmlType="button"
              type="text"
            >
              {t('leftedCartForm.cancelBtn')}
            </Button>
          )}
        </div>
      </Form>
      {isSubmittingForm && <LoadingModal />}
    </>
  );
};

export default LeftedCartOfferForm;
