/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import { useTranslation } from 'react-i18next';
import Loading from '../../common/loading/Loading';
import Routes from './Routes';
import { useEffect } from 'react';
import axios from 'axios';
import { ConfigProvider } from 'antd';
import { ReactNotifications } from 'react-notifications-component';
import DocTitleScrollTop from '../../utils/DocTitleScrollTop';
import '../../i18n';
import UserContext from '../../contexts/user-context/UserProvider';
import myInfoApi from '../../apis/auth/myInfoApi';
import checkRes from '../../utils/checkRes';
import routerLinks from './routerLinks';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import useGeneralSettings from '../../custom-hooks/useGeneralSettings';
import GeneralSettingsContext from '../../contexts/general-settings-context/GeneralSettingsContext';
// import 'moment/locale/ar';
import MyNotificationsContext from '../../contexts/notifications-context/MyNotificationsContext';
import { onMessageListener } from '../../firebase';
import ForegroundNotifications from '../foreground-notification/ForegroundNotification';
import { HelmetProvider } from 'react-helmet-async';
// import arEg from 'antd/es/locale/ar_EG';
// import enUs from 'antd/es/locale/en_US';
// axios.defaults.baseURL = 'http://falas.emir.life/api';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

function App() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { fetchUserCount, user, removeCurrentUser, setCurrentUser } =
    useContext(UserContext);
  // console.log('user : ', user);
  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.dir()]);

  useEffect(() => {
    AOS.init({
      duration: 1500
    });
  }, []);
  const {
    isLoadingGeneralSettings,
    setIsLoadingGeneralSettings,
    fetchGeneralSettingsCount,
    setFetchGeneralSettingsCount,
    fetchedGeneralSettings,
    setFetchedGeneralSettings
  } = useContext(GeneralSettingsContext);
  useGeneralSettings({
    isLoading: isLoadingGeneralSettings,
    setIsLoading: setIsLoadingGeneralSettings,
    fetchCount: fetchGeneralSettingsCount,
    setFetchCount: setFetchGeneralSettingsCount,
    fetchedSettings: fetchedGeneralSettings,
    setFetchedSettings: setFetchedGeneralSettings
  });
  // const location = useGeoLocation();
  // useEffect(() => {
  //   Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
  //   Geocode.setLanguage(i18n.language);
  // }, [location?.loaded, i18n.language]);

  DocTitleScrollTop('');

  const customApiRequest = useCustomApiRequest();
  // console.log('user : ', user);
  useEffect(() => {
    let isMounted = true;
    if (user) {
      customApiRequest(
        myInfoApi(user?.token, i18n.language),
        (res) => {
          if (isMounted) {
            if (checkRes(res)) {
              setCurrentUser({
                ...res?.data?.data,
                token: user?.token,
                accountType: user.accountType,
                activated: user.activated
              });
            } else {
              removeCurrentUser();
              navigate(routerLinks?.signinPage);
            }
          }
        },
        (error) => {}
      );
    }
    return () => {
      isMounted = false;
    };
  }, [fetchUserCount]);

  // useEffect(() => {
  //   moment.locale(i18n.language);
  // }, [i18n.language]);

  // FCM
  const {
    setFcmNotificationFetchCount,
    fcmNotificationPayload,
    fcmNotificationFetchCount,
    setFetchCount,
    setFcmNotificationPayload
  } = useContext(MyNotificationsContext);

  if (onMessageListener()) {
    onMessageListener()
      .then((payload) => {
        // console.log('payload : ', payload);
        setFcmNotificationFetchCount((prev) => prev + 1);
        setFetchCount((prev) => prev + 1);
        setFcmNotificationPayload({
          // title: payload.notification.title,
          // body: payload.notification.body
          ...payload
        });
      })
      .catch((err) => console.log('failed: ', err));
  }

  return (
    <div className={`app app-${i18n.dir()}`}>
      <Suspense fallback={<Loading />}>
        <HelmetProvider>
          <ConfigProvider
            direction={i18n.dir()}
            // locale={i18n?.language === 'ar' ? arEg : enUs}
          >
            <ReactNotifications />

            {user && (
              <ForegroundNotifications
                fcmNotificationPayload={fcmNotificationPayload}
                fcmNotificationFetchCount={fcmNotificationFetchCount}
                setFcmNotificationFetchCount={setFcmNotificationFetchCount}
                setFcmNotificationPayload={setFcmNotificationPayload}
                setFetchCount={setFetchCount}
              />
            )}
            <Routes />
          </ConfigProvider>
        </HelmetProvider>
      </Suspense>
    </div>
  );
}

export default App;
