/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getAllGeneralProductsApi from '../../../apis/general/getAllGeneralProductsApi';
import ArchHeading from '../../../common/arch-heading/ArchHeading';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import SharedProductsSlider from './SharedProductsSlider';
import { LoadingOutlined } from '@ant-design/icons';
// import { Empty } from 'antd';
import './MenWomenSlider.scss';
import menWomenFixedCatId from '../../../const-values/menWomenFixedCatId';
import UserContext from '../../../contexts/user-context/UserProvider';
import FilterCatsContext from '../../../contexts/filter-cats/FilterCatsContext';
import EmptyCompoent from '../../../common/empty-compoent/EmptyComponent';
import noProductsImg from '../../../assets/imgs/icons/no-products-img.svg';
import productStatus from '../../../const-values/productStatus';
import storeStatus from '../../../const-values/storeStatus';

const WomenSlider = () => {
  // SlickSliderBtns('trendy-products-slider', false);
  const { user } = useContext(UserContext);
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState();
  const [allFetchedSlides, setAllFetchedSlides] = useState([]);
  const [fetchSlidesCount, setFetchSlidesCount] = useState(0);
  const { fetchedData: allFetchedFilterCats } = useContext(FilterCatsContext);
  const [selectedCatId, setSelectedCatId] = useState('');
  const customApiRequest = useCustomApiRequest();
  const { t } = useTranslation();
  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    customApiRequest(
      getAllGeneralProductsApi(
        user,
        {
          cat_id: selectedCatId || '',
          catFilter_id: menWomenFixedCatId()?.women
        },
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoading(false);
          if (checkRes(res) && res.data?.data?.data) {
            const data = res.data.data.data;
            setAllFetchedSlides(data);
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, selectedCatId, fetchSlidesCount]);

  const renderFilterBtns = () => {
    const foundCat = allFetchedFilterCats?.find(
      (item) => item?.id == menWomenFixedCatId()?.women
    );
    if (foundCat?.cats?.length > 0)
      return (
        <div className="btns-wrap">
          {foundCat.cats.map((cat) => {
            return (
              <button
                className={`cat-btn ${
                  selectedCatId == cat?.id ? 'active-btn' : ''
                }`}
                onClick={() => {
                  if (selectedCatId == cat?.id) setSelectedCatId(null);
                  else setSelectedCatId(cat?.id);
                }}
                key={cat?.id}
              >
                {cat?.name}
              </button>
            );
          })}
        </div>
      );
    return null;
  };

  const renderCatsFilter = () => {
    return (
      <div className={`men-women-home-slider-filter ${i18n.dir()}`}>
        {allFetchedFilterCats?.length > 0 && renderFilterBtns()}
      </div>
    );
  };

  // SlickSliderBtns('women-slider', isLoading);

  if (isLoading) {
    return (
      <div
        className="custom-container"
        style={{
          paddingBottom: 52
        }}
      >
        <ArchHeading title={t('womensProducts')} />
        {allFetchedFilterCats?.length > 0 && renderCatsFilter()}

        <div
          style={{
            width: 'fit-content',
            margin: 'auto',
            padding: '72px 0'
          }}
        >
          <LoadingOutlined />
        </div>
      </div>
    );
  }

  // if (allFetchedSlides?.length === 0) {
  //   return (
  //     <div
  //       className="custom-container"
  //       style={{
  //         paddingBottom: 52
  //       }}
  //     >
  //       <ArchHeading title={t('homeSections.women')} />
  //       {allFetchedFilterCats?.length > 0 && renderCatsFilter()}

  //       <Empty description={false}>{t('sharedPSlider.noServices')}</Empty>
  //     </div>
  //   );
  // }

  if (allFetchedSlides?.length > 0)
    return (
      <section className="new-products-section">
        <div className="custom-container">
          <div className="arch-heading-wrap">
            <ArchHeading title={t('homeSections.women')} />
            {allFetchedFilterCats?.length > 0 && renderCatsFilter()}
          </div>
          <SharedProductsSlider
            slides={allFetchedSlides
              .filter((obj) => obj?.status === productStatus?.active)
              .filter((obj) => obj?.storeStatus === storeStatus?.active)}
            className="women-slider"
            setFetchCount={setFetchSlidesCount}
          />
        </div>
      </section>
    );
  else if (allFetchedSlides?.length === 0)
    return (
      <section className="new-products-section">
        <div className="custom-container">
          <div className="arch-heading-wrap">
            <ArchHeading title={t('homeSections.men')} />

            {allFetchedFilterCats?.length > 0 && renderCatsFilter()}
          </div>
          <EmptyCompoent img={noProductsImg} p="" />
        </div>
      </section>
    );
  return null;
};
export default WomenSlider;
