/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import addToCartApi from '../../apis/cart-apis/addToCartApi';
import infoSolidImg from '../../assets/imgs/icons/info-solid.svg';
import storeTermsImg from '../../assets/imgs/icons/store-terms.svg';
import CustomAntdDatePicker from '../../common/antd-form-components/CustomAntdDatePicker';
import CustomAntdTimePicker from '../../common/antd-form-components/CustomAntdTimePicker';
import CartIcon from '../../common/icons/CartIcon';
import RadioButtonEmpty from '../../common/icons/RadioButtonEmpty';
import RadioButtonFilled from '../../common/icons/RadioButtonFilled';
import ShieldIcon from '../../common/icons/ShieldIcon';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import routerLinks from '../../components/app/routerLinks';
import userRouterLinks from '../../components/app/user-routes/uesRouterLinks';
import productSettingsOptions, {
  isServiceInStore,
  productSettingsOptionsApiTitles
} from '../../const-values/productSettingsOptions';
import refundOptions from '../../const-values/refundOptions';
import CartContext from '../../contexts/cart-context/CartContext';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import useSingleStore from '../../custom-hooks/useSingleStore';
import { getProductPriceAfterDiscount } from '../../utils/calcServicePrices';
import checkDate, { checkDateTypes } from '../../utils/checkDate';
import checkRes from '../../utils/checkRes';
import errorNotification from '../../utils/errorNotification';
import successNotification from '../../utils/successNotification';
import StoreViewTermsModal from '../single-saloon-page/StoreViewTermsModal';
import ChooseCartOrShoppingModal from './ChooseCartOrShoppingModal';

const GeneralSingleProductForm = ({
  isLoadingSingleProduct,
  fetchedSingleProduct,
  setIsLoadSingleProduct,
  setFetchSingleProductCount
}) => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const params = useParams();
  const { user } = useContext(UserContext);
  const { fetchedData: fetchedSingleStoreDetails } = useSingleStore(
    fetchedSingleProduct?.store_id
  );
  const [viewTermsModalOpened, setViewTermsModalOpened] = useState(false);
  const [chooseModalOpened, setChooseModalOpened] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [productQuantity, setProductQuantity] = useState(1);
  const [total, setTotal] = useState(0);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        date: Yup.mixed().required(t('singleProductPage.dateError')),
        time: Yup.mixed().nullable().required(t('singleProductPage.timeError')),
        optionType: Yup.string().required(t('singleProductPage.serviceOption'))
      })
    ),
    mode: 'all',
    defaultValues: {
      date: null,
      time: null,
      optionType: ''
    }
  });

  const [isToday, setIsToday] = useState(false);
  useEffect(() => {
    if (watch('date')) {
      if (checkDate(watch('date'), checkDateTypes?.isToday)) {
        setIsToday(true);
      } else {
        setIsToday(false);
      }
    }
  }, [watch('date')]);

  useEffect(() => {
    if (watch('date')) {
      setValue('time', null);
    }
  }, [watch('date')]);

  const handleCounterClick = (type, e) => {
    e.preventDefault();
    if (productQuantity > 0) {
      if (type === 'up') {
        if (productQuantity < fetchedSingleProduct.inStock)
          setProductQuantity((prev) => prev + 1);
      } else {
        if (productQuantity > 1) {
          setProductQuantity((prev) => {
            return prev - 1;
          });
        }
      }
    }
  };

  const calcNetTotal = () => {
    return (
      getProductPriceAfterDiscount({
        pPrice: fetchedSingleProduct?.price,
        hasDiscount: fetchedSingleProduct?.hasCode,
        discountType: fetchedSingleProduct?.codeType,
        discountValue: fetchedSingleProduct?.codeValue,
        codeEndDate: fetchedSingleProduct?.codeEndDate
      }) * parseFloat(productQuantity)
    );
  };

  useEffect(() => {
    if (fetchedSingleProduct?.price) {
      if (watch('optionType') && fetchedSingleProduct) {
        const foundOption = productSettingsOptions(t)?.find(
          (obj) => obj?.value == watch('optionType')
        );
        if (foundOption && fetchedSingleProduct[foundOption?.apiTitle]) {
          setTotal((prev) => {
            return (
              calcNetTotal() +
              parseFloat(fetchedSingleProduct[foundOption.apiTitle])
            );
          });
        }
      } else {
        setTotal(calcNetTotal());
      }
    }
  }, [productQuantity, watch('optionType')]);

  useEffect(() => {}, [watch('optionType')]);

  // render option type
  const renderOptionsArr = () => {
    let arrayToMap = productSettingsOptions(t).filter((obj) => {
      if (fetchedSingleProduct[obj.apiTitle]) {
        if (
          obj?.apiTitle === productSettingsOptionsApiTitles?.inStore &&
          fetchedSingleProduct?.isServiceInStore == isServiceInStore?.checked
        ) {
          return obj;
        } else if (obj?.apiTitle !== productSettingsOptionsApiTitles?.inStore) {
          return obj;
        }
        return null;
      }
      return null;
    });

    return (
      arrayToMap?.length > 0 &&
      arrayToMap.map((obj) => {
        return (
          obj && (
            <label
              key={obj?.id}
              className={`radio-input-label ${i18n.dir()} ${
                String(watch(`optionType`)) == String(obj.id) ? 'selected' : ''
              }`}
            >
              <input
                onClick={() => {
                  if (watch('optionType') == obj?.value) {
                    setValue('optionType', '');
                  }
                }}
                type="radio"
                value={obj?.value}
                {...register(`optionType`)}
              />
              {watch(`optionType`) == obj?.value ? (
                <RadioButtonFilled />
              ) : (
                // <CustomImage
                //   className="need-img filled"
                //   src={obj?.logo}
                // />
                <RadioButtonEmpty />
                // <CustomImage className="need-img" src={obj?.logo} />
              )}
              <span className="label-span">
                {obj?.name}{' '}
                {Number(fetchedSingleProduct[obj.apiTitle]) > 0 && (
                  <span>
                    ( {fetchedSingleProduct[obj.apiTitle]} {t('currency.sar')} )
                  </span>
                )}
              </span>
            </label>
          )
        );
      })
    );
  };

  // console.log('watch :, ', watch());
  // console.log('errors : ', errors);

  const customApiRequest = useCustomApiRequest();
  const { setFetchCartCount } = useContext(CartContext);
  const onSubmit = (data) => {
    if (user) {
      const formData = new FormData();
      formData.append('product_id', params?.productId);
      formData.append('quantity', productQuantity);
      if (data.date)
        formData.append('date', moment(data.date).format('YYYY-MM-DD'));
      if (data.time)
        formData.append('time', moment(data.time).format('hh:mm a'));
      formData.append('optionType', data.optionType);
      if (params?.agentKey) formData.append('agentKey', params.agentKey);
      setIsSubmittingForm(true);
      // setIsLoadSingleProduct(true);
      customApiRequest(
        addToCartApi(formData, user?.token, i18n.language),
        (res) => {
          setIsSubmittingForm(false);
          // setIsLoadSingleProduct(false);
          if (checkRes(res)) {
            // navigate(userRouterLinks?.userCartRoute);
            setChooseModalOpened(true);
            reset({
              date: '',
              time: '',
              optionType: ''
            });
            setFetchCartCount((prev) => prev + 1);
            successNotification({
              title: t('successTitle'),
              message:
                i18n.language === 'ar'
                  ? 'تمت اضافة الخدمة بنجاح'
                  : 'Added service successfully'
            });
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          // setIsLoadSingleProduct(false);
          errorNotification({
            title: t('errorTitle'),
            message: 'حاول فى وقت لاحق 12'
          });
        }
      );
    } else {
      navigate(routerLinks?.signinPage);
    }
  };

  const renderRefundOption = () => {
    if (refundOptions(t)?.length > 0) {
      const foundRefundOption = refundOptions(t).find(
        (item) => item.id == fetchedSingleProduct?.refundOption
      );
      if (foundRefundOption)
        return (
          <div className="title-learn-wrap">
            <p className="refund-title">
              <ShieldIcon color="#64022C" />
              {foundRefundOption.title}
            </p>
            <RouterLink to={generalRouterLinks?.refundTermsRoute}>
              {t('learnMore')}
              <img src={infoSolidImg} alt="info" />
            </RouterLink>
          </div>
        );
      return '';
    }
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="add-to-cart-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className="radios-wrap">
            <p
              style={{
                color: '#64022c',
                marginTop: 18,
                marginBottom: 2,
                fontSize: 16
              }}
            >
              {t('product_form.productSettings.label')}
            </p>
            <div
              className="labels-wrap need-label"
              style={{
                marginTop: 0
              }}
            >
              {productSettingsOptions(t)?.length > 0 && renderOptionsArr()}
            </div>
            {errors?.optionType && (
              <p
                className="error-p"
                style={{
                  padding: '0 18px'
                }}
              >
                {errors?.optionType?.message}
              </p>
            )}
          </div>

          <div className="date-time-wrap">
            <label className="custom-date-input order-date-label">
              {/* <span className="label-span"></span> */}
              <CustomAntdDatePicker
                disablePast
                placeholder={t('singleProductPage.selectDate')}
                control={control}
                name={`date`}
                className="order-date-picker"
                formClassName="add-to-cart-form"
                errorMsg={errors?.date && errors.date.message}
              />
            </label>

            {((watch('date') && moment(watch('date')).isSameOrAfter()) ||
              isToday) && (
              <div className="date-picker-wrap order-time-label">
                {/* <p className="label-p">الوقت</p> */}
                <CustomAntdTimePicker
                  disablePast={isToday}
                  placeholder={t('singleProductPage.selectTime')}
                  control={control}
                  name="time"
                  className="order-time-picker"
                  formClassName="add-to-cart-form"
                />
                {errors?.time && (
                  <p className="error-p">{errors.time.message}</p>
                )}
              </div>
            )}
          </div>

          <div className="refund-learn-more-wrapper">
            {renderRefundOption()}
            {/*  */}
            <button
              type="button"
              className="terms-btn"
              onClick={() => setViewTermsModalOpened(true)}
            >
              <img src={storeTermsImg} alt="terms" />
              {t('singleStoreDetailsPage.terms')}

              <div className="learn-more-wrapper">
                {t('learnMore')}
                <img src={infoSolidImg} alt="info" />
              </div>
            </button>
          </div>

          <div className="counters-add-to-cart-btns">
            <div className="counter-btns-wrap">
              <button
                className="count-btn up"
                onClick={(e) => handleCounterClick('up', e)}
              >
                +
              </button>
              <span className="count-value">{productQuantity}</span>
              <button
                className="count-btn down"
                onClick={(e) => handleCounterClick('down', e)}
              >
                -
              </button>
            </div>

            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              // icon={<LoginOutlined />}
              // loading={isSubmittingForm}
              disabled={
                isSubmittingForm ||
                !fetchedSingleProduct?.inStock ||
                fetchedSingleProduct?.inStock == 0
              }
            >
              {!isSubmittingForm ? (
                <p>
                  <CartIcon color="#fff" />
                  <span>{t('singleProductPage.addToCart')} </span>
                  {total > 0 && (
                    <span className="total-span">
                      {' '}
                      ( {total} ) {t('currency.sar')}
                    </span>
                  )}
                </p>
              ) : (
                <p>
                  <LoadingOutlined />
                  <span>{t('singleProductPage.addToCart')}</span>
                  {total > 0 && (
                    <span className="total-span">
                      {' '}
                      ( {total} ) {t('currency.sar')}
                    </span>
                  )}
                </p>
              )}
            </Button>
          </div>

          <RouterLink
            className="saloonStoreLink"
            to={userRouterLinks?.singleStoreRoute(
              fetchedSingleProduct?.store_id
            )}
          >
            {t('singleProductPage.saloonLink')}
          </RouterLink>
        </div>
      </Form>

      {viewTermsModalOpened && (
        <StoreViewTermsModal
          store={fetchedSingleStoreDetails?.storeData}
          modalOpened={viewTermsModalOpened}
          setModalOpened={setViewTermsModalOpened}
        />
      )}

      {chooseModalOpened && (
        <ChooseCartOrShoppingModal
          modalOpened={chooseModalOpened}
          setModalOpened={setChooseModalOpened}
        />
      )}

      {isSubmittingForm && <LoadingModal />}
    </>
  );
};

export default GeneralSingleProductForm;
