/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import './MerchentProductStatisticsChart.scss';
import React from 'react';
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // Line,
  ResponsiveContainer,
  Legend,
  Line,
  AreaChart,
  Area
} from 'recharts';
import ProductStatisticsContext from '../../../contexts/product-statistics-context/ProductStatisticsContext';
import queryString from 'query-string';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DatePicker } from 'antd';
import moment from 'moment';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';

const MerchentProductStatisticsChart = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { fetchedData, setTableFilter } = React.useContext(
    ProductStatisticsContext
  );
  const { search } = useLocation();
  const values = queryString.parse(search);
  // console.log('buy invoice chart: ', buyInvoiceChart);
  // console.log('sale invoice chart: ', saleInvoiceChart);

  const [chartData, setChartData] = React.useState([]);

  React.useEffect(() => {
    const arr = [];
    for (let i = 1; i <= 6; i++) {
      arr.push({
        date: fetchedData[i]?.range,
        orders: fetchedData[i]?.numberOfOrders,
        TotalSales: fetchedData[i]?.price
      });
    }
    setChartData(arr);
  }, [fetchedData]);

  const onChange = (date, dateString) => {
    // setFetchDataCount((prev) => prev + 1);
    setTableFilter((prev) => ({
      ...prev,
      date: dateString
    }));
    navigate(
      `${merchentRouterLinks?.storeProductStatistics(
        params?.store_id,
        params?.product_id
      )}?date=${dateString || ''}`
    );
  };

  return (
    <div className="chart-date-picker-wrapper">
      <DatePicker
        defaultValue={values?.date ? moment(values?.date, 'YYYY-MM') : ''}
        onChange={onChange}
        picker="month"
      />
      <div className="product-charts-wrapper">
        <div style={{ width: '100%', height: 478, direction: 'ltr' }}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              // width={900}
              // height={300}
              // data={data}
              data={chartData}
              margin={{
                top: 5,
                right: 22,
                left: -8,
                bottom: 5
              }}
            >
              <defs>
                <linearGradient id={'colorUv' + 1} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="100%" stopColor="#9d4edd" />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date">
                {/* <Label value="Goal type" offset={-8} position="insideBottom" /> */}
              </XAxis>
              <YAxis />
              <Tooltip />
              <Legend />
              <Area
                type="monotone"
                dataKey="TotalSales"
                strokeWidth="3"
                // stroke="#8884d8"
                stroke="#9d4edd"
                fill="#d4b1f1"
                activeDot={{ r: 8 }}
              />
              {/* <Line type="monotone" dataKey="purchases" stroke="#82ca9d" /> */}
            </AreaChart>
          </ResponsiveContainer>
        </div>

        <div style={{ width: '100%', height: 478, direction: 'ltr' }}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              // width={900}
              // height={300}
              // data={data}
              data={chartData}
              margin={{
                top: 5,
                right: 22,
                left: -8,
                bottom: 5
              }}
            >
              <defs>
                <linearGradient id={'colorUv' + 1} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="100%" stopColor="#9d4edd" />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date">
                {/* <Label value="Goal type" offset={-8} position="insideBottom" /> */}
              </XAxis>
              <YAxis />
              <Tooltip />
              <Legend />
              <Area
                type="monotone"
                dataKey="orders"
                strokeWidth="3"
                // stroke="#8884d8"
                stroke="#fb2e86"
                fill="#febed9"
                activeDot={{ r: 8 }}
              />
              {/* <Line type="monotone" dataKey="purchases" stroke="#82ca9d" /> */}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default MerchentProductStatisticsChart;
