import { Modal } from 'antd';
import fbImg from '../../assets/imgs/icons/share-icons/fb.svg';
// import instagramImg from '../../assets/imgs/icons/share-icons/instagram.svg';
import linkedinImg from '../../assets/imgs/icons/share-icons/linkedin.svg';
import telegramImg from '../../assets/imgs/icons/share-icons/telegram.svg';
import twitterImg from '../../assets/imgs/icons/share-icons/twitter.svg';
import whatsappImg from '../../assets/imgs/icons/share-icons/whatsapp.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { notification } from 'antd';
// import InstagramEmbed from 'react-instagram-embed';

import './CustomShareModal.scss';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
import generalRouterLinks from '../app/general-routes/gerneralRouterLinks';
import { t } from 'i18next';
import CopyIcon from '../../common/icons/PercentageIcon copy';
import { useEffect, useState } from 'react';

const CustomShareModal = ({ modalOpened, setModalOpened, obj }) => {
  const [copyCount, setCopyCount] = useState(0);
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied && copyCount) {
      notification.success({
        message: 'Copied to Clipboard',
        duration: 1.5,
        onClick: () => {
          // console.log('Notification Clicked!');
        }
      });
    }
  }, [copied, copyCount]);

  return (
    <Modal
      className="custom-share-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '842px' }}
      title=""
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
      }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      <p className="share-p">{t('singleProductPage.share')}</p>
      <div className="links-wrap">
        <FacebookShareButton
          url={`${
            process.env.REACT_APP_WEBSITE_URL
          }${generalRouterLinks?.generalSingleProductRoute(obj?.id)}`}
          title={`${obj?.name}\n`}
          separator={'\n'}
        >
          <img src={fbImg} alt="fb" />
        </FacebookShareButton>

        {/* <InstagramEmbed
            url={`${
              process.env.REACT_APP_WEBSITE_URL
            }${generalRouterLinks?.generalSingleProductRoute(obj?.id)}`}
            // url="https://instagr.am/p/Zw9o4/"
            clientAccessToken="123|456"
            maxWidth={320}
            hideCaption={false}
            containerTagName="div"
            protocol=""
            injectScript
            onLoading={() => {}}
            onSuccess={() => {}}
            onAfterRender={() => {}}
            onFailure={() => {}}
          /> */}

        <LinkedinShareButton
          url={`${
            process.env.REACT_APP_WEBSITE_URL
          }${generalRouterLinks?.generalSingleProductRoute(obj?.id)}`}
          title={`${obj?.name}\n`}
          separator={'\n'}
        >
          <img src={linkedinImg} alt="linkedin" />
        </LinkedinShareButton>
        <WhatsappShareButton
          url={`${
            process.env.REACT_APP_WEBSITE_URL
          }${generalRouterLinks?.generalSingleProductRoute(obj?.id)}`}
          title={`${obj?.name}\n`}
          separator={'\n'}
        >
          <img src={whatsappImg} alt="whatsapp" />
        </WhatsappShareButton>
        <TwitterShareButton
          url={`${
            process.env.REACT_APP_WEBSITE_URL
          }${generalRouterLinks?.generalSingleProductRoute(obj?.id)}`}
          title={`${obj?.name}\n`}
          separator={'\n'}
        >
          <img src={twitterImg} alt="twitter" />
        </TwitterShareButton>
        <TelegramShareButton
          url={`${
            process.env.REACT_APP_WEBSITE_URL
          }${generalRouterLinks?.generalSingleProductRoute(obj?.id)}`}
          title={`${obj?.name}\n`}
          separator={'\n'}
        >
          <img src={telegramImg} alt="telegram" />
        </TelegramShareButton>
      </div>

      <p className="copy-p">{t('singleProductPage.copy')}</p>

      <div className="to-copy-text-box">
        {/* <div className="box-label">{sharedT('affiliateLink')}</div> */}
        <div className="box-content">
          <span>{`${
            process.env.REACT_APP_WEBSITE_URL
          }${generalRouterLinks?.generalSingleProductRoute(obj?.id)}`}</span>
          <CopyToClipboard
            text={`${
              process.env.REACT_APP_WEBSITE_URL
            }${generalRouterLinks?.generalSingleProductRoute(obj?.id)}`}
            onCopy={() => setCopied(true)}
          >
            <button
              className="affiliate-action-btn copy"
              onClick={() => setCopyCount((prev) => prev + 1)}
            >
              <CopyIcon />
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </Modal>
  );
};

export default CustomShareModal;
