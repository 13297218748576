import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/App';
import { BrowserRouter as Router } from 'react-router-dom';
import Loading from './common/loading/Loading';
import { UserProvider } from './contexts/user-context/UserProvider';
// import { MainAppBarProvider } from './contexts/main-app-bar-context/MainAppBarProvider';
//
import 'react-circular-progressbar/dist/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import 'antd/dist/antd.css';
import 'antd/dist/antd.less';
import 'aos/dist/aos.css';
import 'react-phone-number-input/style.css';
import './scss/index.scss';
import { SelectedPageProvider } from './contexts/selected-page-context/SelectedPageContext';
import { GeneralSettingsProvider } from './contexts/general-settings-context/GeneralSettingsContext';
import { SharedAppProvider } from './contexts/shared-app-context/SharedAppContext';
import { FilterCatsProvider } from './contexts/filter-cats/FilterCatsContext';
import { CatsProvider } from './contexts/cats-context/CatsContext';
import { CartProvider } from './contexts/cart-context/CartContext';
import { MyNotificationsProvider } from './contexts/notifications-context/MyNotificationsContext';

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <UserProvider>
      <Router>
        <MyNotificationsProvider>
          <SelectedPageProvider>
            <CartProvider>
              <GeneralSettingsProvider>
                <FilterCatsProvider>
                  <CatsProvider>
                    <SharedAppProvider>
                      <App />
                    </SharedAppProvider>
                  </CatsProvider>
                </FilterCatsProvider>
              </GeneralSettingsProvider>
            </CartProvider>
          </SelectedPageProvider>
        </MyNotificationsProvider>
      </Router>
    </UserProvider>
  </Suspense>,
  document.getElementById('root')
);
