/* eslint-disable eqeqeq */
import accountTypes from '../../const-values/accountTypes';
import appPages from '../../const-values/appPages';

const mainAppBarLinks = (i18n, t, user) => {
  const {
    homePage,
    topSaloons,
    merchentDashboard,
    merchentStores,
    merchentEmployees,
    merchentSettings,
    merchentOther,
    merchentTutorial
  } = appPages(i18n, t, user);

  const sharedLinks = () => {
    return [
      {
        id: homePage?.id,
        name: homePage?.name,
        link: homePage?.link,
        icon: homePage?.icon ? <homePage.icon /> : null,
        sub: homePage?.sub
      },
      // {
      //   id: todayOffers?.id,
      //   name: todayOffers?.name,
      //   link: todayOffers?.link,
      //   icon: todayOffers?.icon ? <todayOffers.icon /> : null,
      //   sub: todayOffers?.sub
      // },
      {
        id: topSaloons?.id,
        name: topSaloons?.name,
        link: topSaloons?.link,
        icon: topSaloons?.icon ? <topSaloons.icon /> : null,
        sub: topSaloons?.sub
      }
    ];
  };

  if (user?.accountType == accountTypes()?.user) {
    return [...sharedLinks()];
  } else if (user?.accountType == accountTypes()?.merchent) {
    return [
      {
        id: merchentDashboard?.id,
        name: merchentDashboard?.name,
        link: merchentDashboard?.link,
        icon: merchentDashboard?.icon ? <merchentDashboard.icon /> : null,
        sub: merchentDashboard?.sub
      },
      {
        id: merchentStores?.id,
        name: merchentStores?.name,
        link: merchentStores?.link,
        icon: merchentStores?.icon ? <merchentStores.icon /> : null,
        sub: merchentStores?.sub
      },
      {
        id: merchentEmployees?.id,
        name: merchentEmployees?.name,
        link: merchentEmployees?.link,
        icon: merchentEmployees?.icon ? <merchentEmployees.icon /> : null,
        sub: merchentEmployees?.sub
      },
      {
        id: merchentSettings?.id,
        name: merchentSettings?.name,
        link: merchentSettings?.link,
        icon: merchentSettings?.icon ? <merchentSettings.icon /> : null,
        sub: merchentStores?.sub
      },
      {
        id: merchentOther?.id,
        name: merchentOther?.name,
        link: merchentOther?.link,
        icon: merchentOther?.icon ? <merchentOther.icon /> : null,
        sub: merchentOther?.sub
      },
      {
        id: merchentTutorial?.id,
        name: merchentTutorial?.name,
        link: merchentTutorial?.link,
        icon: merchentTutorial?.icon ? <merchentTutorial.icon /> : null,
        sub: merchentTutorial?.sub
      }
    ];
  } else if (user?.accountType == accountTypes()?.employee) {
    return [
      {
        id: merchentDashboard?.id,
        name: merchentDashboard?.name,
        link: merchentDashboard?.link,
        icon: merchentDashboard?.icon ? <merchentDashboard.icon /> : null,
        sub: merchentDashboard?.sub
      },
      {
        id: merchentStores?.id,
        name: merchentStores?.name,
        link: merchentStores?.link,
        icon: merchentStores?.icon ? <merchentStores.icon /> : null,
        sub: merchentStores?.sub
      },
      {
        id: merchentSettings?.id,
        name: merchentSettings?.name,
        link: merchentSettings?.link,
        icon: merchentSettings?.icon ? <merchentSettings.icon /> : null,
        sub: merchentStores?.sub
      }
    ];
  }
  return [...sharedLinks()];
  // return [
  //   {
  //     id: 1,
  //     name: t('main_app_bar_links.home'),
  //     link: routerLinks?.homePage
  //   },
  //   {
  //     id: 2,
  //     name: t('main_app_bar_links.categories'),
  //     link: routerLinks?.categoriesRoute
  //   },
  //   {
  //     id: 4,
  //     name: t('main_app_bar_links.startSelling'),
  //     link: routerLinks?.startSellingRoute
  //   },
  //   {
  //     id: 5,
  //     name: t('main_app_bar_links.aboutUs'),
  //     link: routerLinks?.aboutUsRoute
  //   }
  // ];
};

export default mainAppBarLinks;
