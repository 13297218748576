/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, memo, useEffect } from 'react';
import Geocode from 'react-geocode';
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
  InfoBox
} from '@react-google-maps/api';
import useGeoLocation from '../../custom-hooks/useGeoLocation';
import { useCallback } from 'react';
import saloonMapIcon from '../../assets/imgs/icons/saloon-map-icon.png';
import './CustomMap.scss';

const libraries = ['places'];
const options = {
  disableDefaultUI: true,
  zoomControl: true,
  sensor: true
};
const infoBoxOptions = {
  closeBoxURL: '',
  enableEventPropagation: true
};

const CustomMap = (props) => {
  const {
    width,
    height,
    selectedLocation,
    setSelecectedLocation,
    selectedAddress,
    setSelectedAddress,
    defaultMarkers,
    isDraggable = false,
    hasAutoComplete = false,
    zoom = 10
  } = props;
  const location = useGeoLocation();
  const containerStyle = {
    width: width ? width : '100vw',
    height: height ? height : '80vh'
  };

  const [clickedMarker, setClickedMarker] = useState(null);
  const [autoComplete, setAutoComplete] = useState(null);
  const [center, setCenter] = useState(null);

  useEffect(() => {
    if (
      location?.loaded &&
      location?.coordinates?.lat &&
      location?.coordinates?.lng &&
      (!defaultMarkers || defaultMarkers?.length === 0)
    ) {
      setCenter({
        lat: location.coordinates.lat,
        lng: location.coordinates.lng
      });
    } else if (
      defaultMarkers?.length > 0 &&
      defaultMarkers[0]?.lat &&
      defaultMarkers[0]?.lng
    ) {
      setCenter({
        lat: defaultMarkers[0]?.lat ? Number(defaultMarkers[0].lat) : '',
        lng: defaultMarkers[0]?.lng ? Number(defaultMarkers[0].lng) : ''
      });
    }
  }, [location.loaded, location?.coordinates]);

  useEffect(() => {
    let isMounted = true;

    if (selectedLocation.lat && selectedLocation.lng) {
      Geocode.fromLatLng(selectedLocation.lat, selectedLocation.lng).then(
        (response) => {
          const address =
            response?.results?.length > 0
              ? response.results[0]?.formatted_address
              : '';
          if (isMounted) {
            setSelectedAddress(address);
          }
        },
        (error) => {
          if (isMounted) console.error(error);
        }
      );
    }

    return () => (isMounted = false);
  }, [selectedLocation?.lat, selectedLocation?.lng]);

  useEffect(() => {
    let isMounted = true;

    if (clickedMarker?.lat && clickedMarker?.lng) {
      Geocode.fromLatLng(clickedMarker?.lat, clickedMarker?.lng).then(
        (response) => {
          if (isMounted) {
            const address =
              response?.results?.length > 0
                ? response.results[0]?.formatted_address
                : '';
            setSelectedAddress(address);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }

    return () => (isMounted = false);
  }, [clickedMarker?.lat, clickedMarker?.lng]);

  const handleClickMarker = (marker, v) => {
    if (v?.latLng?.lat() && v?.latLng?.lng()) {
      setClickedMarker({
        lat: v.latLng.lat(),
        lng: v.latLng.lng()
      });

      if (
        props?.handleClickStoreMarker &&
        typeof props?.handleClickStoreMarker === 'function'
      ) {
        props.handleClickStoreMarker(marker?.id);
      }
    }
  };

  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    // googleMapsApiKey: '',
    // googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'ar'
  });
  useEffect(() => {
    // Geocode.setApiKey('');
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
  }, []);

  const autoCompOnLoad = useCallback((autocomp) => {
    // console.log('autocomplete: ', autocomp);
    setAutoComplete(autocomp);
  }, []);

  const onPlaceChanged = useCallback(() => {
    // console.log('auto complete : ', autoComplete);
    if (autoComplete !== null) {
      // console.log("autocomplete getPlace(): ", autoComplete.getPlace());
      // console.log("autocomplete", autoComplete);
      if (autoComplete.getPlace()?.geometry?.location) {
        setSelecectedLocation({
          lat: autoComplete.getPlace().geometry.location.lat(),
          lng: autoComplete.getPlace().geometry.location.lng()
        });
      }
      setSelectedAddress(autoComplete.getPlace().formatted_address);
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  }, [autoComplete]);

  // const onLoadMarker = marker => {
  // 	console.log("marker: ", marker);
  // };
  const handleMarkerDragStart = useCallback(() => {
    setSelectedAddress('');
  }, []);
  const handleMarkerDragEnd = useCallback((e) => {
    setSelecectedLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    });
  }, []);

  const onLoadInfoBox = useCallback((info) => {
    // console.log('infoBox: ', info);
  }, []);

  const getInfoBoxPosition = () => {
    if (selectedLocation?.lat && selectedLocation?.lng) {
      return selectedLocation;
    } else if (clickedMarker?.lat && clickedMarker?.lng) {
      return clickedMarker;
    }
    return center;
  };

  if (loadError) return 'Error loading map';
  if (!isLoaded) return 'loading map';
  return (
    <>
      <GoogleMap
        onClick={(v) => {
          setClickedMarker(null);
        }}
        mapContainerStyle={containerStyle}
        options={options}
        center={
          selectedLocation.lat && selectedLocation.lng
            ? selectedLocation
            : center
        }
        zoom={zoom}
        // onLoad={onLoad}
        // onUnmount={onUnmount}
        className="custom-google-map"
      >
        <>
          {hasAutoComplete && (
            <Autocomplete
              onLoad={autoCompOnLoad}
              onPlaceChanged={onPlaceChanged}
            >
              <input
                type="text"
                placeholder="Enter your place..."
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: `332px`,
                  height: `48px`,
                  padding: `0 12px`,
                  borderRadius: `3px`,
                  boxShadow: `0 2px 4px rgba(0, 0, 0, 0.1)`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                  position: 'absolute',
                  left: '50%',
                  transform: 'translate(-50%)',
                  textAlign: 'left',
                  direction: 'ltr'
                }}
              />
            </Autocomplete>
          )}

          {defaultMarkers?.length >= 0 ? (
            defaultMarkers.map((marker, index) => {
              return (
                <Marker
                  key={index}
                  // icon={mapIcon}
                  // onLoad={onLoadMarker}
                  icon={saloonMapIcon}
                  position={{
                    lat: marker?.lat ? parseFloat(marker.lat) : '',
                    lng: marker?.lng ? parseFloat(marker.lng) : ''
                  }}
                  draggable={isDraggable || false}
                  onClick={(v) => {
                    handleClickMarker(marker, v);
                  }}
                />
              );
            })
          ) : (
            <Marker
              // icon={mapIcon}
              // onLoad={onLoadMarker}
              position={
                selectedLocation.lat && selectedLocation.lng
                  ? {
                      lat: Number(selectedLocation.lat),
                      lng: Number(selectedLocation.lng)
                    }
                  : {
                      lat: Number(center?.lat),
                      lng: Number(center?.lng)
                    }
              }
              draggable={isDraggable || false}
              onDragStart={handleMarkerDragStart}
              onDragEnd={handleMarkerDragEnd}
            />
          )}

          {(selectedAddress || clickedMarker) && (
            <InfoBox
              onLoad={onLoadInfoBox}
              options={infoBoxOptions}
              position={getInfoBoxPosition()}
              style={{
                transform: 'translate(-50%)'
              }}
            >
              <div
                style={{
                  backgroundColor: '#00a6ff',
                  opacity: 0.9,
                  padding: 12,
                  width: '220px',
                  borderRadius: '5px'
                }}
              >
                <div style={{ fontSize: 16, color: `#fff` }}>
                  {selectedAddress}
                </div>
              </div>
            </InfoBox>
          )}
        </>
      </GoogleMap>
      {/* {location.loaded
        ? JSON.stringify(location, null, 2)
        : 'Location data not available yet.'}
      <h1>
        center:
        {JSON.stringify(center, null, 2)}
      </h1>
      <h1>
        selected location:
        {JSON.stringify(selectedLocation, null, 2)}
      </h1>
      <h1>
        address:
        {selectedAddress}
      </h1> */}
    </>
  );
};

export default memo(CustomMap);
