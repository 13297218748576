import { useContext } from 'react';
import { CustomStepperWrapProvider } from '../../../contexts/custom-stepper-wrap-context/CustomStepperWrapContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import UserCartPage from '../../../pages/user-cart-page/UserCartPage';
import NewAddressPage from '../../../pages/user-profile-menu-pages/user-addressess-page/NewAddressPage';
import UserAddressesPage from '../../../pages/user-profile-menu-pages/user-addressess-page/UserAddressesPage';
import UserSingleAddressPage from '../../../pages/user-profile-menu-pages/user-addressess-page/UserSingleAddressPage';
import UserChangePasswordPage from '../../../pages/user-profile-menu-pages/user-change-password-page/UserChangePasswordPage';
// import UserChatCenterPage from '../../../pages/user-profile-menu-pages/user-chat-center-page/UserChatCenterPage';
import UserDashboardPage from '../../../pages/user-profile-menu-pages/user-dashboard-page/UserDashboardPage';
import UserOrdersPage from '../../../pages/user-profile-menu-pages/user-orders-page/UserOrdersPage';
import UserSettingsPage from '../../../pages/user-profile-menu-pages/user-settings-page/UserSettingsPage';
import protectMe from '../../../utils/protectMe';
import routerLinks from '../routerLinks';
import userRouterLinks from './uesRouterLinks';
import { UserOrdersProvider } from '../../../contexts/user-orders-context/UserOrdersContext';
import UserWalletPage from '../../../pages/user-profile-menu-pages/user-wallet-page/UserWalletpage';
import { UserFavouriesProvider } from '../../../contexts/user-favourites-context/UserFavouritesContext';
import UserFavouritesPage from '../../../pages/user-favourites-page/UserFavouritesPage';
// import { ChatProvider } from '../../../contexts/chat-context/ChatContext';
import UserBankAccountPage from '../../../pages/user-profile-menu-pages/user-bank-account-page/UserBankAccountPage';
import accountTypes from '../../../const-values/accountTypes';
import UserAffiliateStatisticsPage from '../../../pages/user-profile-menu-pages/user-afffiliate-statistics-page/UserAffiliateStatisticsPage';

const UserRoutes = () => {
  const { user, loggedIn } = useContext(UserContext);

  return [
    // generalRoute(
    //   routerLinks?.homePage,
    //   <HomePage />,
    //   3,
    //   routerLinks?.signinPage,
    //   loggedIn,
    //   user
    // ),

    // generalRoute(
    //   userRouterLinks?.singleStoreRoute(),
    //   <SingleStoreProvider>
    //     <SingleStorePage />
    //   </SingleStoreProvider>,
    //   'general_single_store_route',
    //   routerLinks?.signinPage,
    //   loggedIn,
    //   user
    // ),
    protectMe(
      userRouterLinks?.userCartRoute,
      <CustomStepperWrapProvider>
        <UserCartPage />
      </CustomStepperWrapProvider>,
      'user_cart_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    protectMe(
      userRouterLinks?.userDashboard,
      <UserDashboardPage />,
      'user_dashboard_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    protectMe(
      userRouterLinks?.accountSettings,
      <UserSettingsPage />,
      'user_settings_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    protectMe(
      userRouterLinks?.changePassword,
      <UserChangePasswordPage />,
      'user_change_pass_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    protectMe(
      userRouterLinks?.bankAccount,
      <UserBankAccountPage />,
      'user_bank_account_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    protectMe(
      userRouterLinks?.myAddresses,
      <UserAddressesPage />,
      'user_addresses_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    protectMe(
      userRouterLinks?.createNewAddressRoute,
      <NewAddressPage />,
      'user_new_address_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    protectMe(
      userRouterLinks?.editAddressRoute(),
      <NewAddressPage />,
      'user_new_address_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    protectMe(
      userRouterLinks?.singleAddress(),
      <UserSingleAddressPage />,
      'user_single_address_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    protectMe(
      userRouterLinks?.myOrders,
      <UserOrdersProvider>
        <UserOrdersPage />
      </UserOrdersProvider>,
      'user_orders_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    protectMe(
      userRouterLinks?.myWallet,
      <UserWalletPage />,
      'user_wallet_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    protectMe(
      userRouterLinks?.statistics,
      <UserAffiliateStatisticsPage />,
      'user_statistics_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    protectMe(
      userRouterLinks?.myFavRoute,
      <UserFavouriesProvider>
        <UserFavouritesPage />
      </UserFavouriesProvider>,
      'user_fav_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    )
    // protectMe(
    //   userRouterLinks?.userChatCenterRoute,
    //   <ChatProvider>
    //     <UserChatCenterPage />
    //   </ChatProvider>,
    //   'user_chat_center_route_id',
    //   routerLinks?.signinPage,
    //   loggedIn,
    //   user,
    //   [accountTypes()?.user]
    // )
  ];
};

export default UserRoutes;
