/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getAllGeneralProductsApi from '../../apis/general/getAllGeneralProductsApi';
import GeneralProductsContext from '../../contexts/general-products-context/GeneralProductsContext';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import { Pagination } from 'antd';
import ProductCard from '../merchent-pages/products-page/ProductCard';
import UserContext from '../../contexts/user-context/UserProvider';
import productTypes from '../../const-values/productTypes';
import accountTypes from '../../const-values/accountTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import './GeneralProductsPageData.scss';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import queryString from 'query-string';
import noProductsImg from '../../assets/imgs/icons/no-products-img.svg';
import EmptyCompoent from '../../common/empty-compoent/EmptyComponent';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import productStatus from '../../const-values/productStatus';
import storeStatus from '../../const-values/storeStatus';

const GeneralProductsPageData = () => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const {
    // tableFilter,
    isLoadingTableData,
    setIsLoadingTableData,
    setAllFetchedTableData,
    fetchTableDataCount,
    setFetchTableDataCount,
    allFetchedTableData,
    tablePagination,
    setTablePagination,
    selectedViewAsObj
  } = useContext(GeneralProductsContext);
  const { search } = useLocation();
  const values = queryString.parse(search);
  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;
    setIsLoadingTableData(true);
    customApiRequest(
      getAllGeneralProductsApi(
        user,
        { ...values },
        // { ...tableFilter, ...values },
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingTableData(false);
          if (checkRes(res) && res.data?.data?.data) {
            const data = res.data.data.data;
            setAllFetchedTableData(data);
            if (res.data.data?.pagination) {
              setTablePagination(res.data.data.pagination);
            }
          }
        }
      },
      (error) => {
        setIsLoadingTableData(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchTableDataCount, search]);

  const getType = () => {
    if (user?.accountType == accountTypes()?.user)
      return productTypes?.userProduct;
    else if (
      user?.accountType == accountTypes()?.merchent ||
      user?.accountType == accountTypes()?.employee
    )
      return productTypes?.merchentProduct;
    return productTypes?.generalProduct;
  };

  const renderProductsList = () => {
    return (
      allFetchedTableData?.length > 0 &&
      allFetchedTableData
        .filter((obj) => obj?.status === productStatus?.active)
        .filter((obj) => obj?.storeStatus === storeStatus?.active)
        .map((obj) => {
          return (
            <ProductCard
              className={selectedViewAsObj?.name || ''}
              setFetchParentCount={setFetchTableDataCount}
              key={obj?.id}
              card={obj}
              type={getType()}
            />
          );
        })
    );
  };

  const renderSharedProductsSection = () => {
    return (
      <section
        initial={{
          opacity: 0
        }}
        animate={{
          opacity: 1
        }}
        exit={{
          opacity: 0,
          transition: {
            duration: 0.3
          }
        }}
        className="general-products-page-data"
      >
        <div className={`products-wrap ${selectedViewAsObj?.name || ''}`}>
          {renderProductsList()}
        </div>

        {tablePagination?.total > 0 && (
          <div className="pagination-total-wrapper">
            <Pagination
              showSizeChanger={false}
              defaultCurrent={1}
              showQuickJumper
              locale={{
                jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
                // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
                page: ''
              }}
              current={tablePagination.current_page}
              pageSize={tablePagination.per_page}
              total={tablePagination.total}
              // itemRender={itemRender}
              onChange={(page, pageSize) => {
                setFetchTableDataCount((prev) => prev + 1);
                navigate(
                  `${generalRouterLinks?.generalProductsRoute}?store_id=${
                    values?.store_id || ''
                  }&country_id=${values?.country_id || ''}&cat_id=${
                    values?.cat_id || ''
                  }&catFilter_id=${values?.catFilter_id || ''}&discount=${
                    values?.discount || ''
                  }&delivery=${values?.delivery || ''}&rate=${
                    values?.rate || ''
                  }&fromPrice=${values?.fromPrice || ''}&toPrice=${
                    values?.toPrice || ''
                  }&paymentType=${values?.paymentType || ''}&sort=${
                    values?.sort || ''
                  }&page=${page || 1}`
                );
              }}
              hideOnSinglePage={true}
            />

            <div className="total-wrapper">
              <span>{t('totalResult.about')}</span>
              <span>{tablePagination.total}</span>
              <span>{t('totalResult.results')}</span>
            </div>
          </div>
        )}
      </section>
    );
  };

  //
  //
  //

  if (isLoadingTableData) {
    return (
      <>
        {/* <LoadingOutlined /> */}
        <LoadingModal />
      </>
      // <div
      //   style={{
      //     padding: '132px 0',
      //     margin: '32px auto'
      //   }}
      // >
      //   <LoadingOutlined />
      // </div>
    );
  } else if (allFetchedTableData?.length === 0) {
    return <EmptyCompoent img={noProductsImg} p="No Product Found !!" />;
  } else if (allFetchedTableData?.length > 0)
    return renderSharedProductsSection();

  return null;
};

export default GeneralProductsPageData;
