const filterTypes = {
  country_id: 'country_id',
  cat_id: 'cat_id',
  catFilter_id: 'catFilter_id',
  discount: 'discount',
  delivery: 'delivery',
  rate: 'rate',
  fromPrice: 'fromPrice',
  toPrice: 'toPrice',
  paymentType: 'paymentType'
};

export default filterTypes;
