import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import filterTypes from '../../const-values/filterTypes';

export const handleNavigateAll = (
  checkBoxChecked,
  name,
  arr,
  navigate,
  values
) => {
  // if country_id filter
  if (name === filterTypes?.country_id) {
    navigate(
      `${generalRouterLinks?.generalProductsRoute}?store_id=${
        values?.store_id || ''
      }&country_id=${
        checkBoxChecked && arr?.length > 0 ? arr.map((item) => item.id) : ''
      }&cat_id=${values?.cat_id || ''}&catFilter_id=${
        values?.catFilter_id || ''
      }&discount=${values?.discount || ''}&delivery=${
        values?.delivery || ''
      }&rate=${values?.rate || ''}&fromPrice=${
        values?.fromPrice || ''
      }&toPrice=${values?.toPrice || ''}&paymetType=${
        values?.paymentType || ''
      }sort=${values?.sort || ''}&page=${values?.page || 1}`
    );
  } else if (name === filterTypes?.catFilter_id) {
    navigate(
      `${generalRouterLinks?.generalProductsRoute}?store_id=${
        values?.store_id || ''
      }&country_id=${values?.country_id || ''}&cat_id=${
        values?.cat_id || ''
      }&catFilter_id=${
        checkBoxChecked && arr?.length > 0 ? arr.map((item) => item.id) : ''
      }&discount=${values?.discount || ''}&delivery=${
        values?.delivery || ''
      }&rate=${values?.rate || ''}&fromPrice=${
        values?.fromPrice || ''
      }&toPrice=${values?.toPrice || ''}&paymetType=${
        values?.paymentType || ''
      }sort=${values?.sort || ''}&page=${values?.page || 1}`
    );
  } else if (name === filterTypes?.delivery) {
    navigate(
      `${generalRouterLinks?.generalProductsRoute}?store_id=${
        values?.store_id || ''
      }&country_id=${values?.country_id || ''}&cat_id=${
        values?.cat_id || ''
      }&catFilter_id=${values?.catFilter_id || ''}&discount=${
        values?.discount || ''
      }&delivery=${
        checkBoxChecked && arr?.length > 0 ? arr.map((item) => item.id) : ''
      }&rate=${values?.rate || ''}&fromPrice=${
        values?.fromPrice || ''
      }&toPrice=${values?.toPrice || ''}&paymetType=${
        values?.paymentType || ''
      }sort=${values?.sort || ''}&page=${values?.page || 1}`
    );
  }
};

export const handleNavigateSingleFilter = (
  name,
  checkboxFieldValuesArr,
  navigate,
  values
) => {
  // check for country_id
  if (name === filterTypes?.country_id) {
    navigate(
      `${generalRouterLinks?.generalProductsRoute}?store_id=${
        values?.store_id || ''
      }&country_id=${
        checkboxFieldValuesArr?.length > 0 ? checkboxFieldValuesArr : ''
      }&cat_id=${values?.cat_id || ''}&catFilter_id=${
        values?.catFilter_id || ''
      }&discount=${values?.discount || ''}&delivery=${
        values?.delivery || ''
      }&rate=${values?.rate || ''}&fromPrice=${
        values?.fromPrice || ''
      }&toPrice=${values?.toPrice || ''}&paymetType=${
        values?.paymentType || ''
      }sort=${values?.sort || ''}&page=${values?.page || 1}`
    );
  } else if (name === filterTypes?.catFilter_id) {
    navigate(
      `${generalRouterLinks?.generalProductsRoute}?store_id=${
        values?.store_id || ''
      }&country_id=${values?.country_id || ''}&cat_id=${
        values?.cat_id || ''
      }&catFilter_id=${
        checkboxFieldValuesArr?.length > 0 ? checkboxFieldValuesArr : ''
      }&discount=${values?.discount || ''}&delivery=${
        values?.delivery || ''
      }&rate=${values?.rate || ''}&fromPrice=${
        values?.fromPrice || ''
      }&toPrice=${values?.toPrice || ''}&paymetType=${
        values?.paymentType || ''
      }sort=${values?.sort || ''}&page=${values?.page || 1}`
    );
  } else if (name === filterTypes?.delivery) {
    navigate(
      `${generalRouterLinks?.generalProductsRoute}?store_id=${
        values?.store_id || ''
      }&country_id=${values?.country_id || ''}&cat_id=${
        values?.cat_id || ''
      }&catFilter_id=${values?.catFilter_id || ''}&discount=${
        values?.discount || ''
      }&delivery=${
        checkboxFieldValuesArr?.length > 0 ? checkboxFieldValuesArr : ''
      }&rate=${values?.rate || ''}&fromPrice=${
        values?.fromPrice || ''
      }&toPrice=${values?.toPrice || ''}&paymetType=${
        values?.paymentType || ''
      }sort=${values?.sort || ''}&page=${values?.page || 1}`
    );
  }
};
