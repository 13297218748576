/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, createContext, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import getCartApi from '../../apis/cart-apis/getCartApi';
import accountTypes from '../../const-values/accountTypes';
import productSettingsOptions from '../../const-values/productSettingsOptions';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { getProductPriceAfterDiscountAndOffer } from '../../utils/calcServicePrices';
import checkRes from '../../utils/checkRes';
import UserContext from '../user-context/UserProvider';
import {
  getAllProductsTotalAfterTax,
  getAllProductsTotalWithoutTax
} from './cartCalculations';

const INITIAL_VALUES = {
  isLoadingCart: false,
  setIsLoadingCart: (v) => {},
  isRemovingProduct: false,
  setIsRemovingProduct: (v) => {},
  isSubmittingOrder: false,
  setIsSubmittingOrder: (v) => {},
  fetchCartCount: 0,
  setFetchCartCount: (v) => {},
  cartOrderRes: null,
  setCartOrderRes: (v) => {},
  //
  tax: 0,
  setTax: (v) => {},
  cartCardsPricesArr: [],
  setCartCardsPricesArr: (v) => {},
  subTotal: 0,
  setSubTotal: (v) => {},
  total: 0,
  setTotal: (v) => {},
  selectedAddress: null,
  setSelectedAddress: (v) => {},
  paymentType: '',
  setPaymentType: (v) => {},
  discountCode: '',
  setDiscountCode: (v) => {},
  discountType: '', // 1 => value, 2 => percentage
  setDiscountType: (v) => {},
  discountValue: 0,
  setDiscountValue: (v) => {},
  discountStoreId: '',
  setDiscountStoreId: (v) => {},
  discountCodeId: '',
  setDiscountCodeId: (v) => {},
  //
  cartProducts: null,
  setCartProducts: (v) => {},
  cartItemsCount: 0,
  setCartItemsCount: (v) => {}
};

const CartContext = createContext(INITIAL_VALUES);

export const CartProvider = ({ children }) => {
  const [cartOrderRes, setCartOrderRes] = useState(INITIAL_VALUES.cartOrderRes);
  const [isLoadingCart, setIsLoadingCart] = useState(
    INITIAL_VALUES.isLoadingCart
  );
  const [isRemovingProduct, setIsRemovingProduct] = useState(
    INITIAL_VALUES.isRemovingProduct
  );
  const [isSubmittingOrder, setIsSubmittingOrder] = useState(
    INITIAL_VALUES.isSubmittingOrder
  );
  const [fetchCartCount, setFetchCartCount] = useState(
    INITIAL_VALUES.fetchCartCount
  );
  const [discountCode, setDiscountCode] = useState(INITIAL_VALUES.discountCode);
  const [discountType, setDiscountType] = useState(INITIAL_VALUES.discountType);
  const [discountValue, setDiscountValue] = useState(
    INITIAL_VALUES.discountValue
  );
  const [discountStoreId, setDiscountStoreId] = useState(
    INITIAL_VALUES.discountStoreId
  );
  const [discountCodeId, setDiscountCodeId] = useState(
    INITIAL_VALUES.discountCodeId
  );
  const [tax, setTax] = useState(INITIAL_VALUES.tax);
  const [cartCardsPricesArr, setCartCardsPricesArr] = useState(
    INITIAL_VALUES?.cartCardsPricesArr
  );
  const [subTotal, setSubTotal] = useState(INITIAL_VALUES.subTotal);
  const [total, setTotal] = useState(INITIAL_VALUES.total);
  const [cartProducts, setCartProducts] = useState(INITIAL_VALUES.cartProducts);
  const [selectedAddress, setSelectedAddress] = useState(
    INITIAL_VALUES.selectedAddress
  );
  const [paymentType, setPaymentType] = useState(INITIAL_VALUES.paymentType);
  //
  const [cartItemsCount, setCartItemsCount] = useState(
    INITIAL_VALUES?.cartItemsCount
  );

  const { t, i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    if (user && user?.accountType === accountTypes()?.user) {
      let isMounted = true;
      setIsLoadingCart(true);
      customApiRequest(
        getCartApi(null, user?.token, i18n.language),
        (res) => {
          if (isMounted) {
            setIsLoadingCart(false);
            if (checkRes(res) && res.data?.data?.cart) {
              const data = res.data.data.cart;
              setCartProducts(data);
              setTax(res.data.data.tax);
            }
          }
        },
        (error) => {
          setIsLoadingCart(false);
        }
      );

      return () => {
        isMounted = false;
      };
    }
  }, [user?.id, fetchCartCount, i18n.language]);

  // cart items count
  useEffect(() => {
    if (cartProducts?.length >= 0) {
      setCartItemsCount(
        cartProducts.reduce((acc, curr) => {
          return curr?.quantity + acc;
        }, 0)
      );
    }
  }, [cartProducts]);

  const foundOption = (productOptionId) =>
    productSettingsOptions(t)?.length > 0 &&
    productSettingsOptions(t).find((obj) => obj?.value == productOptionId);

  useEffect(() => {
    if (cartProducts?.length > 0) {
      const mappedProducts = cartProducts.map((p) => {
        return {
          productPrice: getProductPriceAfterDiscountAndOffer({
            pPrice: p?.product?.price,
            hasDiscount: p?.product?.hasCode,
            discountType: p?.product?.codeType,
            discountValue: p?.product?.codeValue,
            hasOffer: p?.hasOffer,
            offerType: p?.offerType,
            offerValue: p?.offerValue,
            offerEndDate: p?.offerEndDate,
            codeEndDate: p?.product?.codeEndDate
          }),
          optionPrice: foundOption(p?.optionType)
            ? p?.product[foundOption(p?.optionType).apiTitle]
            : 0,
          quantity: p?.quantity
        };
      });

      setSubTotal(getAllProductsTotalWithoutTax(mappedProducts).toFixed(2));
    }
  }, [cartProducts]);

  useEffect(() => {
    if (subTotal > 0) {
      if (discountValue) {
        setTotal(
          getAllProductsTotalAfterTax(subTotal, tax).toFixed(2) -
            parseFloat(discountValue)
        );
      } else {
        setTotal(getAllProductsTotalAfterTax(subTotal, tax).toFixed(2));
      }
    }
  }, [subTotal, discountValue]);

  return (
    <CartContext.Provider
      value={{
        isLoadingCart,
        setIsLoadingCart,
        isRemovingProduct,
        setIsRemovingProduct,
        isSubmittingOrder,
        setIsSubmittingOrder,
        fetchCartCount,
        setFetchCartCount,
        discountCode,
        setDiscountCode,
        discountType,
        setDiscountType,
        discountValue,
        setDiscountValue,
        discountStoreId,
        setDiscountStoreId,
        discountCodeId,
        setDiscountCodeId,
        tax,
        setTax,
        cartCardsPricesArr,
        setCartCardsPricesArr,
        subTotal,
        setSubTotal,
        total,
        setTotal,
        cartProducts,
        setCartProducts,
        selectedAddress,
        setSelectedAddress,
        paymentType,
        setPaymentType,
        //
        cartItemsCount,
        setCartItemsCount,
        cartOrderRes,
        setCartOrderRes
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
