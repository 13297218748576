import { useState, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { viewAsArr } from '../../const-values/viewAsOptions';

const sharedLabelT = (t, key) => t(`productsPage.${key}`);

const INITIAL_VALUES = () => {
  return {
    isLoadingTableData: false,
    setIsLoadingTableData: (v) => {},
    loadingFav: false,
    setLoadingFav: (v) => {},
    formModalOpened: false,
    setFormModalOpened: (v) => {},
    detailsModalOpened: false,
    setDetailsModalOpened: (v) => {},
    fetchTableDataCount: 0,
    setFetchTableDataCount: (v) => {},
    allFetchedTableData: null,
    setAllFetchedTableData: (v) => {},
    rejectedModalOpened: false,
    setRejectedModalOpened: (v) => {},
    selectedViewAsObj: null,
    setSelectedViewAsObj: (v) => {},
    //
    tablePagination: {
      current_page: 1,
      per_page: 0,
      total: 0
    },
    setTablePagination: (v) => {},
    selectedTableRow: null,
    setSelectedTableRow: (v) => {},
    // filter
    tableFilter: {
      page: 1,
      store_id: '',
      country_id: {
        label: (t) =>
          t && typeof t === 'function' ? sharedLabelT(t, 'countries') : '',
        value: []
      },
      cat_id: {
        label: (t) =>
          t && typeof t === 'function' ? sharedLabelT(t, 'categories') : '',
        value: ''
      },
      catFilter_id: {
        label: (t) =>
          t && typeof t === 'function' ? sharedLabelT(t, 'specificFor') : '',
        value: []
      },
      discount: {
        label: (t) =>
          t && typeof t === 'function' ? sharedLabelT(t, 'discount') : '',
        value: ''
      },
      delivery: {
        label: (t) =>
          t && typeof t === 'function' ? sharedLabelT(t, 'delivery') : '',
        value: []
      },
      rate: {
        label: (t) =>
          t && typeof t === 'function' ? sharedLabelT(t, 'rate') : '',
        value: ''
      },
      fromPrice: {
        label: (t) =>
          t && typeof t === 'function' ? sharedLabelT(t, 'priceRange') : '',
        value: ''
      },
      toPrice: {
        label: (t) =>
          t && typeof t === 'function' ? sharedLabelT(t, 'priceRange') : '',
        value: ''
      },
      paymentType: {
        label: (t) =>
          t && typeof t === 'function' ? sharedLabelT(t, 'paymentMethods') : '',
        value: ''
      },
      sort: {
        label: (t) =>
          t && typeof t === 'function' ? sharedLabelT(t, 'sort') : '',
        value: ''
      }
    },
    setTableFilter: (v) => {}
  };
};

const GeneralProductsContext = createContext(INITIAL_VALUES());

export const GeneralProductsProvider = ({ children }) => {
  const { t } = useTranslation();
  const [isLoadingTableData, setIsLoadingTableData] = useState(
    INITIAL_VALUES().isLoadingTableData
  );
  const [loadingFav, setLoadingFav] = useState(INITIAL_VALUES().loadingFav);

  const [fetchTableDataCount, setFetchTableDataCount] = useState(
    INITIAL_VALUES().fetchTableDataCount
  );
  const [allFetchedTableData, setAllFetchedTableData] = useState(
    INITIAL_VALUES().allFetchedTableData
  );
  const [selectedTableRow, setSelectedTableRow] = useState(
    INITIAL_VALUES().selectedTableRow
  );
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES().tablePagination
  );
  const [formModalOpened, setFormModalOpened] = useState(
    INITIAL_VALUES().modalOpened
  );
  const [detailsModalOpened, setDetailsModalOpened] = useState(
    INITIAL_VALUES().detailsModalOpened
  );
  const [rejectedModalOpened, setRejectedModalOpened] = useState(
    INITIAL_VALUES().rejectedModalOpened
  );
  //
  const [selectedViewAsObj, setSelectedViewAsObj] = useState(
    viewAsArr(t)?.length > 0 && viewAsArr(t)[0]
  );
  //
  const [tableFilter, setTableFilter] = useState(INITIAL_VALUES()?.tableFilter);

  return (
    <GeneralProductsContext.Provider
      value={{
        isLoadingTableData,
        setIsLoadingTableData,
        loadingFav,
        setLoadingFav,
        formModalOpened,
        setFormModalOpened,
        detailsModalOpened,
        setDetailsModalOpened,
        fetchTableDataCount,
        setFetchTableDataCount,
        allFetchedTableData,
        setAllFetchedTableData,
        selectedTableRow,
        setSelectedTableRow,
        rejectedModalOpened,
        setRejectedModalOpened,
        //
        selectedViewAsObj,
        setSelectedViewAsObj,
        //
        tablePagination,
        setTablePagination,
        tableFilter,
        setTableFilter
      }}
    >
      {children}
    </GeneralProductsContext.Provider>
  );
};

export default GeneralProductsContext;
