/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import userRouterLinks from '../../components/app/user-routes/uesRouterLinks';
import CartContext from '../../contexts/cart-context/CartContext';
import CustomStepperWrapContext from '../../contexts/custom-stepper-wrap-context/CustomStepperWrapContext';
import checkDate, { checkDateTypes } from '../../utils/checkDate';
import errorNotification from '../../utils/errorNotification';
import CartCard from './CartCard';
import DiscountCodeForm from './DiscountCodeForm';
import OrderSummery from './OrderSummery';

const CartStepOne = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { cartProducts } = useContext(CartContext);
  // const { step, setStep, isLastStep, childrenArray } = useContext(
  //   CustomStepperWrapContext
  // );
  const { setStep } = useContext(CustomStepperWrapContext);

  const handleClickNextStep = () => {
    let servicesWithInvalidDate = [];
    let isInvalidTime = false;
    const handleFoundObjWithSameDateOrBefore = (obj) => {
      // check if before today
      if (
        checkDate(moment(obj?.date), checkDateTypes?.isBeforeToday) &&
        obj?.time
      ) {
        isInvalidTime = true;
        servicesWithInvalidDate.push(obj);
      }

      // check if today or before
      else if (
        checkDate(moment(obj?.date), checkDateTypes?.isToday) &&
        obj?.time
      ) {
        const objTimeArr = obj.time.trim().split('');
        if (objTimeArr?.length > 0) {
          let objTimeId = objTimeArr.splice(objTimeArr.length - 2, 2).join(''); // pm or am
          // objTimeArr => ['0', '7', ':', '2', '1']
          let pureObjTimeArr = objTimeArr.join('').trim().split(':'); // ['07', '21']
          ////////
          const nowTimeArr = moment().format('hh:mm a').trim().split('');
          if (nowTimeArr?.length > 0) {
            let nowTimeId = nowTimeArr
              .splice(nowTimeArr.length - 2, 2)
              .join(''); // pm or am
            let pureNowTimeArr = nowTimeArr.join('').trim().split(':');

            // remember this check is in the same day
            // then
            // check if the same am or the same pm
            if (objTimeId === nowTimeId) {
              // am === am , pm === pm
              // then check for hours

              // first check for special case at hours = 12

              if (
                (pureObjTimeArr[0] == 12 && pureNowTimeArr[0] != 12) ||
                (pureObjTimeArr[0] != 12 && pureNowTimeArr[0] == 12)
              ) {
                // check if obj hours is 12 and now hours is not 12
                if (pureObjTimeArr[0] == 12 && pureNowTimeArr[0] != 12) {
                  // 12:30 pm obj time
                  // 02:14 pm now time
                  isInvalidTime = true;
                  servicesWithInvalidDate.push(obj);
                }
                // check if obj hours is 12 and now hours is not 12
                else if (pureObjTimeArr[0] == 12 && pureNowTimeArr[0] != 12) {
                  // 02:30 pm obj time
                  // 12:14 pm now time
                  isInvalidTime = false;
                }
              } else {
                if (pureObjTimeArr[0] < pureNowTimeArr[0]) {
                  // 01:30 pm obj time
                  // 02:14 pm now time
                  isInvalidTime = true;
                  servicesWithInvalidDate.push(obj);
                } else if (pureObjTimeArr[0] === pureNowTimeArr[0]) {
                  // then check for minutes
                  // 02:30 pm obj time
                  // 02:14 pm now time
                  if (pureObjTimeArr[1] < pureNowTimeArr[1]) {
                    isInvalidTime = true;
                    servicesWithInvalidDate.push(obj);
                  } else {
                    // 02:30 pm obj time
                    // 02:40 pm now time
                    isInvalidTime = false;
                  }
                } else {
                  isInvalidTime = false;
                }
              }
            }
            // check if objTimeId => am, and nowTimeId => pm
            else if (objTimeId === 'am' && nowTimeId === 'pm') {
              // then this is invalid time
              isInvalidTime = true;
              servicesWithInvalidDate.push(obj);
            }
            // check if objTimeId => pm, and nowTimeId => am
            else if (objTimeId === 'pm' && nowTimeId === 'am') {
              // then this is valid time
              isInvalidTime = false;
            }
          }
          ///////////////////////////////////
        }
      }

      // if (isInvalidTime) {
      //   const foundInPrev = servicesWithInvalidDate.find(
      //     (i) => i?.id === obj?.id
      //   );
      //   if (!foundInPrev) {
      //     servicesWithInvalidDate = [...servicesWithInvalidDate, obj];
      //   }
      // }
    };

    if (cartProducts?.length > 0) {
      for (let obj of cartProducts) {
        handleFoundObjWithSameDateOrBefore(obj);
      }
    }

    //  check for every item in servicesWithinvalidDate arr
    // if this item in cartProducts array
    // then remove this obj from servicesWithInvalidDate arr
    if (servicesWithInvalidDate?.length >= 0) {
      if (cartProducts?.length === 0) servicesWithInvalidDate = [];
      else {
        for (let InvalidDateObj of servicesWithInvalidDate) {
          const foundInCartProducts = cartProducts.find(
            (item) => item?.id === InvalidDateObj?.id
          );
          if (!foundInCartProducts) {
            servicesWithInvalidDate.filter(
              (obj) => obj?.id !== InvalidDateObj?.id
            );
          }
        }
      }
    }

    if (servicesWithInvalidDate?.length > 0) {
      const errorMessage = () => {
        return (
          <div className="err-msgs-wrapper">
            {servicesWithInvalidDate.map((serv) => {
              return (
                <div key={serv?.id} className="msg-wrapper">
                  {t('userCartPage.serviceDate')} {serv?.product?.name}{' '}
                  {t('userCartPage.expired')}
                </div>
              );
            })}
          </div>
        );
      };
      errorNotification({
        title: t('errorTitle'),
        message: errorMessage()
      });
    } else {
      setStep((prev) => prev + 1);
    }
  };

  // TODO delete me

  return (
    <div className="stepper-step cart-step-one">
      <div className="items-summery-wrap">
        <div className="cart-cards-wrap">
          {cartProducts.map((obj) => {
            return <CartCard card={obj} key={obj?.id} />;
          })}
        </div>
        <div
          style={{
            display: 'grid',
            alignContent: 'start',
            gap: 18
          }}
        >
          <OrderSummery />
          <DiscountCodeForm />

          <div className="btns-wrapper">
            <button
              className="checkout-btn"
              onClick={() => {
                handleClickNextStep();
              }}
            >
              {t('checkoutForm.checkoutBtn')}
            </button>
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="back-btn"
              to={userRouterLinks?.userCartRoute}
            >
              {t('checkoutForm.backShoppingLink')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartStepOne;
