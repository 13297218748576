import moment from 'moment';

export const checkDateTypes = {
  isToday: 1,
  isTodayOrBefore: 2,
  isTodayOrAfter: 3,
  isBeforeToday: 4,
  isAfter: 5
};
const checkDate = (dateObj, type) => {
  const watchedDate = moment(dateObj);
  const formYear = watchedDate.year();
  const formMonth = watchedDate.month() + 1;
  const formDay = watchedDate.date();
  const nowYear = moment().year();
  const nowMonth = moment().month() + 1;
  const nowDay = moment().date();

  if (type === checkDateTypes?.isToday) {
    if (formYear === nowYear && formMonth === nowMonth && formDay === nowDay) {
      return true;
    }
  } else if (type === checkDateTypes?.isTodayOrBefore) {
    if (formYear < nowYear) {
      return true;
    } else if (formYear === nowYear) {
      // check for month
      if (formMonth < nowMonth) {
        return true;
      } else if (formMonth === nowMonth) {
        // check for day
        if (formDay <= nowDay) {
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  } else if (type === checkDateTypes?.isBeforeToday) {
    if (formYear < nowYear) {
      return true;
    } else if (formYear === nowYear) {
      // check for month
      if (formMonth < nowMonth) {
        return true;
      } else if (formMonth === nowMonth) {
        // check for day
        if (formDay < nowDay) {
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  } else if (type === checkDateTypes?.isTodayOrAfter) {
    if (formYear < nowYear) {
      return true;
    } else if (formYear === nowYear) {
      // check for month
      if (formMonth > nowMonth) {
        return true;
      } else if (formMonth === nowMonth) {
        // check for day
        if (formDay >= nowDay) {
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  }
  return false;
};

export default checkDate;
