import axios from 'axios';

const getAllGeneralProductsApi = async (user, paramsFilter, langIsoCode) => {
  const headers = (user) =>
    !user
      ? {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          lang: langIsoCode
        }
      : {
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          lang: langIsoCode
        };

  try {
    // const url = `/publicProducts/get?store_id=${paramsFilter?.store_id || ''}
    const url = `/publicProducts/get?country_id=${
      paramsFilter?.country_id || ''
    }&cat_id=${paramsFilter?.cat_id || ''}&catFilter_id=${
      paramsFilter?.catFilter_id || ''
    }&discount=${paramsFilter?.discount || ''}&delivery=${
      paramsFilter?.delivery || ''
    }&rate=${paramsFilter?.rate || ''}&fromPrice=${
      paramsFilter?.fromPrice || ''
    }&toPrice=${paramsFilter?.toPrice || ''}&paymentType=${
      paramsFilter?.paymentType || ''
    }&sort=${paramsFilter?.sort || ''}&page=${paramsFilter?.page || 1}`;
    const res = await axios.get(url, {
      headers: headers(user)
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllGeneralProductsApi;
