/* eslint-disable eqeqeq */

import { Tag } from 'antd';
import moment from 'moment';
import { transactionsStatusArr } from '../../../const-values/transactionsStatus';

const tableColumns = (
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  i18n,
  t
) => {
  const renderStatus = (record) => {
    const foundObj = transactionsStatusArr(t).find(
      (obj) => obj.value === record.status
    );
    if (foundObj) return <Tag color={foundObj?.color}>{foundObj?.name}</Tag>;
    return '---';
  };
  return [
    {
      title: 'ID',
      width: 82,
      dataIndex: 'id',
      render: (_, record) => {
        return <div className="row-cell row-text">#{record.id}</div>;
      }
    },
    {
      title: t('walletPage.transferDate'),
      width: 122,
      dataIndex: 'date',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record.created_at
              ? moment(record.created_at).format('DD-MM-YYYY')
              : '---'}
          </div>
        );
      }
    },
    {
      title: `${t('walletPage.price')} ( ${t('currency.sar')} )`,
      width: 122,
      dataIndex: 'price',
      render: (_, record) => {
        return <div className="row-cell row-text">{record.price || '---'}</div>;
      }
    },
    {
      title: t('walletPage.transactionStatus'),
      dataIndex: 'status',
      width: 102,
      render: (_, record) => {
        return (
          <div
            className="row-cell row-text"
            style={{
              width: 'fit-content',
              margin: 'auto'
            }}
          >
            {renderStatus(record)}
          </div>
        );
      }
    },
    {
      title: `${t('walletPage.storeName')}`,
      dataIndex: 'price',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.store?.name || '---'}
          </div>
        );
      }
    },
    {
      title: (
        <div>
          <p>{t('walletPage.walletBalance')}</p>
          <p>( {t('currency.sar')} )</p>
        </div>
      ),
      // title:  ( ${t('currency.sar')} )`,
      width: 142,
      dataIndex: 'walletBalance',
      render: (_, record) => {
        return <div className="row-cell row-text">{record.price || '---'}</div>;
      }
    },
    {
      title: t('walletPage.accountNumber'),
      dataIndex: 'bank_city',
      width: 132,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.store?.bank?.shortNum || '---'}
          </div>
        );
      }
    },
    {
      title: t('walletPage.bank'),
      dataIndex: 'bank',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.store?.bank?.name || '---'}
          </div>
        );
      }
    },
    {
      title: t('walletPage.bankFee'),
      dataIndex: 'bankFee',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">{record?.bankFee || '---'}</div>
        );
      }
    },
    {
      title: t('walletPage.transferFee'),
      dataIndex: 'transferFee',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.transferFee || '---'}
          </div>
        );
      }
    },
    {
      title: t('walletPage.transferNumber'),
      dataIndex: 'transferNumber',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.transferNumber || '---'}
          </div>
        );
      }
    },
    {
      title: t('walletPage.naomyFee'),
      dataIndex: 'naomyFee',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">{record?.naoomFee || '---'}</div>
        );
      }
    },
    {
      title: `${t('walletPage.total')} ( ${t('currency.sar')} )`,
      dataIndex: 'total',
      width: 172,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.priceAfterNaoomiRate || '---'}
          </div>
        );
      }
    }
    // {
    //   title: t('walletPage.bankCity'),
    //   dataIndex: 'bank_city',
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         {record?.store?.bank?.city || '---'}
    //       </div>
    //     );
    //   }
    // }

    // {
    //   title: t('walletPage.longNum'),
    //   dataIndex: 'bank_city',
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         {record?.store?.bank?.longNum || '---'}
    //       </div>
    //     );
    //   }
    // }
  ];
};

export default tableColumns;
