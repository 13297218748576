import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CartContext from '../../contexts/cart-context/CartContext';

const OrderSummery = () => {
  const { t } = useTranslation();
  const { subTotal, total, discountValue, tax } = useContext(CartContext);
  const sharedT = (key) => t(`checkoutForm.${key}`);
  return (
    <div className="summery-wrap">
      <div className="summery-card">
        <div className="title"></div>
        <div className="sub-total">
          <span>{sharedT('subTotal')}</span>
          <span>
            {subTotal} {t('currency.sar')}
          </span>
        </div>
        <div className="fees">
          <span>{sharedT('fees')}</span>
          <span>{tax}%</span>
        </div>
        <div className="sub-total">
          <span>{sharedT('discount')}</span>
          <span>
            {discountValue || 0} {t('currency.sar')}
          </span>
        </div>

        <div className="order-total">
          <span>{sharedT('total')}</span>
          <span>
            {total} {t('currency.sar')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrderSummery;
