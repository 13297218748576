/* eslint-disable eqeqeq */
import * as Yup from 'yup';
import discountCodeTypes from '../../../const-values/discountCodeTypes';
import SUPPORTED_FORMATS from '../../../const-values/supportedFileFormats';
import getFileSize from '../../../utils/getFileSize';

const baseRequiredErrTransString = (t, key) =>
  t(`product_form.${key}.errors.required`);
const baseErrorTrans = (t, key, errType, options) =>
  t(`product_form.${key}.errors.${errType}`, options);
const sharedSchema = (t) => {
  return {
    name_ar: Yup.string().required(baseRequiredErrTransString(t, 'nameAr')),
    name_en: Yup.string().required(baseRequiredErrTransString(t, 'nameEn')),
    cat_id: Yup.string().required(baseRequiredErrTransString(t, 'cat_id')),
    catFilter_id: Yup.string().required(
      baseRequiredErrTransString(t, 'catFilter_id')
    ),
    price: Yup.string()
      .required(baseRequiredErrTransString(t, 'price'))
      .matches(
        /^(\d+)?(\.\d+)?$/,
        baseRequiredErrTransString(t, 'catFilter_id')
      ),
    inStock: Yup.string()
      .required(baseRequiredErrTransString(t, 'inStock'))
      .matches(/^(\d+)?(\.\d+)?$/, baseRequiredErrTransString(t, 'inStock')),
    refundOption: Yup.string().required('اختار طريقة الاسترجاع'),
    codeType: Yup.string().test(
      'codeType',
      baseRequiredErrTransString(t, 'discountType'),
      (v, context) => {
        let result = true;
        if (context?.parent?.hasCode && (!v || !v.match(/^(\d+)?(\.\d+)?$/)))
          result = false;
        return result;
      }
    ),
    fixedValue: Yup.string()
      .test(
        'fixedValue',
        baseRequiredErrTransString(t, 'fixedValue'),
        (v, context) => {
          let result = true;
          if (
            context?.parent?.hasCode &&
            context?.parent?.codeType == discountCodeTypes?.fixedValue &&
            (!v || !v.match(/^(\d+)?(\.\d+)?$/))
          )
            result = false;
          return result;
        }
      )
      .test(
        'fixedValue',
        t('product_form.fixedValue.errors.invalid'),
        (v, context) => {
          let result = true;
          if (
            context?.parent?.hasCode &&
            context?.parent?.codeType == discountCodeTypes?.fixedValue &&
            v &&
            v.match(/^(\d+)?(\.\d+)?$/) &&
            context?.parent?.price &&
            parseFloat(v) >= parseFloat(context?.parent?.price)
          )
            result = false;
          return result;
        }
      ),
    percentageValue: Yup.string()
      .test(
        'percentageValue',
        baseRequiredErrTransString(t, 'percentageValue'),
        (v, context) => {
          let result = true;
          if (
            context?.parent?.hasCode &&
            context?.parent?.codeType == discountCodeTypes?.percentageValue &&
            (!v || !v.match(/^(\d+)?(\.\d+)?$/))
          )
            result = false;
          return result;
        }
      )
      .test(
        'percentageValue',
        t('product_form.percentageValue.errors.invalid'),
        (v, context) => {
          let result = true;
          if (
            context?.parent?.hasCode &&
            context?.parent?.codeType == discountCodeTypes?.percentageValue &&
            (v || v.match(/^(\d+)?(\.\d+)?$/)) &&
            parseFloat(v) >= 100
          )
            result = false;
          return result;
        }
      ),
    inStore: Yup.object().shape({
      price: Yup.string().test(
        'inStore.price',
        baseRequiredErrTransString(t, 'settings'),
        (v, context) => {
          let result = true;
          if (context?.parent?.checked && (!v || !v.match(/^(\d+)?(\.\d+)?$/)))
            result = false;
          return result;
        }
      )
    }),
    insideCity: Yup.object().shape({
      price: Yup.string().test(
        'insideCity.price',
        baseRequiredErrTransString(t, 'settings'),
        (v, context) => {
          let result = true;
          if (context?.parent?.checked && (!v || !v.match(/^(\d+)?(\.\d+)?$/)))
            result = false;
          return result;
        }
      )
    }),
    outsideCity: Yup.object().shape({
      price: Yup.string().test(
        'outsideCity.price',
        baseRequiredErrTransString(t, 'settings'),
        (v, context) => {
          let result = true;
          if (context?.parent?.checked && (!v || !v.match(/^(\d+)?(\.\d+)?$/)))
            result = false;
          return result;
        }
      )
    }),
    anotherCountry: Yup.object().shape({
      price: Yup.string().test(
        'anotherCountry.price',
        baseRequiredErrTransString(t, 'settings'),
        (v, context) => {
          let result = true;
          if (context?.parent?.checked && (!v || !v.match(/^(\d+)?(\.\d+)?$/)))
            result = false;
          return result;
        }
      )
    })
  };
};

const newProductFormValidationSchema = (t, selectedSingleProductDetails) => {
  return Yup.object().shape({
    ...sharedSchema(t),
    logo: Yup.mixed().required(baseRequiredErrTransString(t, 'logo')),
    productImages: Yup.mixed()
      .required(baseRequiredErrTransString(t, 'productImages'))
      .test(
        'productImages',
        baseErrorTrans(t, 'productImages', 'formatError'),
        (value) => {
          let result = true;
          if (value && value.length) {
            for (let fileObj of value) {
              if (!SUPPORTED_FORMATS.pdfImgs.includes(fileObj.type)) {
                result = false;
              }
            }
          }
          return result;
        }
      )
      .test(
        'productImages',
        baseErrorTrans(t, 'productImages', 'sizeError', {
          maxSize: 1
        }),
        (value) => {
          let result = true;
          if (value && value.length) {
            for (let fileObj of value) {
              if (fileObj.size > getFileSize(1)) {
                result = false;
              }
            }
          }
          return result;
        }
      )
      .test(
        'productImages',
        t('product_form.productImages.errors.maxCount', {
          maxCount: 6
        }),
        (v, context) => {
          let result = true;
          if (
            (v && v?.length > 6) ||
            selectedSingleProductDetails?.productImages?.length + v?.length > 6
          )
            result = false;
          return result;
        }
      ),
    video: Yup.mixed()
      .notRequired()
      .test(
        'video',
        baseErrorTrans(t, 'video', 'sizeError', {
          maxSize: 1
        }),
        (value) => {
          let result = true;
          if (value && value.length) {
            for (let fileObj of value) {
              if (fileObj.size > getFileSize(3)) {
                result = false;
              }
            }
          }
          return result;
        }
      ),

    codeAttach: Yup.mixed()
      .notRequired()
      .test(
        'codeAttach',
        baseRequiredErrTransString(t, 'codeAttach'),
        (v, context) => {
          let result = true;
          if (context?.parent?.hasCode && !v) result = false;
          return result;
        }
      )
      .test(
        'codeAttach',
        baseErrorTrans(t, 'codeAttach', 'formatError'),
        (value) => {
          let result = true;
          if (value && value.length) {
            for (let fileObj of value) {
              if (!SUPPORTED_FORMATS.wordPdfImgs.includes(fileObj.type)) {
                result = false;
              }
            }
          }
          return result;
        }
      )
      .test(
        'codeAttach',
        baseErrorTrans(t, 'codeAttach', 'sizeError', {
          maxSize: 1
        }),
        (value) => {
          let result = true;
          if (value && value.length) {
            for (let fileObj of value) {
              if (fileObj.size > getFileSize(1)) {
                result = false;
              }
            }
          }
          return result;
        }
      )
  });
};

const editProductFormValidationSchema = (t, selectedSingleProductDetails) => {
  return Yup.object().shape({
    ...sharedSchema(t),
    productImages: Yup.mixed()
      .notRequired()
      .test(
        'productImages',
        baseErrorTrans(t, 'productImages', 'formatError'),
        (value) => {
          let result = true;
          if (value && value.length) {
            for (let fileObj of value) {
              if (!SUPPORTED_FORMATS.pdfImgs.includes(fileObj.type)) {
                result = false;
              }
            }
          }
          return result;
        }
      )
      .test(
        'productImages',
        baseErrorTrans(t, 'productImages', 'sizeError', {
          maxSize: 1
        }),
        (value) => {
          let result = true;
          if (value && value.length) {
            for (let fileObj of value) {
              if (fileObj.size > getFileSize(1)) {
                result = false;
              }
            }
          }
          return result;
        }
      )
      .test(
        'productImages',
        t('product_form.productImages.errors.maxCount', {
          maxCount: 6
        }),
        (v, context) => {
          let result = true;
          if (
            (v && v?.length > 6) ||
            selectedSingleProductDetails?.productImages?.length + v?.length > 6
          )
            result = false;
          return result;
        }
      )
  });
};

const getProductFormSchema = (selectedSingleProductDetails, t) => {
  if (!selectedSingleProductDetails)
    return newProductFormValidationSchema(t, selectedSingleProductDetails);
  return editProductFormValidationSchema(t, selectedSingleProductDetails);
};

export default getProductFormSchema;
