/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomMap from '../custom-map/CustomMap';
import userRouterLinks from '../app/user-routes/uesRouterLinks';
import { useNavigate } from 'react-router-dom';
import './MapModal.scss';

const MapModal = ({ modalVisible, setModalVisible, stores }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedLocation, setSelecectedLocation] = React.useState({
    lat: '',
    lng: ''
  });
  const [selectedAddress, setSelectedAddress] = React.useState('');
  const sharedT = (key) => t(`mapModal.${key}`);

  const getDefualtMarkersArr = () => {
    if (stores?.length > 0) {
      return stores.filter((obj) => obj?.lat && obj?.lng);
    } else return [];
  };
  return (
    <Modal
      wrapClassName="map-modal-wrapper"
      transitionName=""
      className="map-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '994px' }}
      title={<div className="modal-title">{sharedT('title')}</div>}
      open={modalVisible}
      okText={sharedT('save')}
      cancelText={sharedT('cancel')}
      onOk={() => {
        setModalVisible(false);
        // navigate(userRouterLinks?.singleStoreRoute(2));
      }}
      onCancel={() => {
        setModalVisible(false);
      }}
      footer={false}
    >
      <CustomMap
        width="100%"
        height="70vh"
        zoom={4}
        selectedLocation={selectedLocation}
        setSelecectedLocation={setSelecectedLocation}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        defaultMarkers={getDefualtMarkersArr()}
        handleClickStoreMarker={(id) =>
          navigate(userRouterLinks?.singleStoreRoute(id))
        }
      />
    </Modal>
  );
};

export default MapModal;
