/* eslint-disable react-hooks/exhaustive-deps */

import GeneralproductsFilter from './GeneralProductsFilter';
import GeneralProductsPageData from './GeneralProductsPageData';
import './GeneralProductsPage.scss';
import { FilterOutlined } from '@ant-design/icons';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import { useTranslation } from 'react-i18next';
import routerLinks from '../../components/app/routerLinks';
import GeneralProductsSelectedFilter from './GeneralProductsSelectedFilter';
import useCountries from '../../custom-hooks/useCountries';
import usePriceFilter from '../../custom-hooks/usePriceFilter';
import { useContext, useState } from 'react';
import { Drawer } from 'antd';
import FilterCatsContext from '../../contexts/filter-cats/FilterCatsContext';
import CatsContext from '../../contexts/cats-context/CatsContext';

const GeneralProductsPage = () => {
  const { t } = useTranslation();
  // const { isLoadingTableData, loadingFav } = useContext(GeneralProductsContext);
  const { fetchedData: allFetchedCats } = useContext(CatsContext);
  const { allFetchedCountries } = useCountries();
  const { fetchedData: allFetchedFilterCats } = useContext(FilterCatsContext);
  const { allFetchedPriceFilter } = usePriceFilter();

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const renderFilterDrawer = () => {
    return (
      <div className="general-products-drawer-filter-wrapper">
        <button className="filter-btn" onClick={showDrawer}>
          <FilterOutlined /> {t('productsPage.filter')}
        </button>
        <Drawer
          className="general-products-drawer"
          title="Basic Drawer"
          placement="right"
          onClose={onClose}
          open={visible}
          width={'80%'}
        >
          <GeneralproductsFilter
            allFetchedCats={allFetchedCats}
            allFetchedCountries={allFetchedCountries}
            allFetchedFilterCats={allFetchedFilterCats}
            allFetchedPriceFilter={allFetchedPriceFilter}
          />
        </Drawer>
      </div>
    );
  };

  return (
    <div className="general-products-page">
      <div className="breadcrumb-title">
        <CustomBreadcrumb
          arr={[
            {
              title: t('main_app_bar_links.home'),
              isLink: true,
              to: routerLinks?.homePage
            },
            {
              title: t('productsPage.title'),
              isLink: false
            }
          ]}
        />
      </div>

      <div className="custom-container">
        <div className="page-content-wrap">
          {renderFilterDrawer()}

          <GeneralproductsFilter
            allFetchedCats={allFetchedCats}
            allFetchedCountries={allFetchedCountries}
            allFetchedFilterCats={allFetchedFilterCats}
            allFetchedPriceFilter={allFetchedPriceFilter}
          />
          <div className="selected-filter-data-wrapper">
            <GeneralProductsSelectedFilter
              allFetchedCats={allFetchedCats}
              allFetchedCountries={allFetchedCountries}
              allFetchedFilterCats={allFetchedFilterCats}
              allFetchedPriceFilter={allFetchedPriceFilter}
            />
            <GeneralProductsPageData />
          </div>
        </div>
      </div>

      {/* {isLoadingTableData && <LoadingModal />} */}
      {/* {loadingFav && <LoadingModal />} */}
    </div>
  );
};

export default GeneralProductsPage;
