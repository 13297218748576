import React from 'react';
import { useController } from 'react-hook-form';
import { TimePicker } from 'antd';
import moment from 'moment';
import arEg from 'antd/es/locale/ar_EG';
const CustomAntdTimePicker = ({
  control,
  name,
  label,
  placeholder,
  validateStatus,
  defaultValue,
  errorMsg,
  className,
  formClassName,
  disablePast
}) => {
  const {
    // field: { ...inputProps }
    field
  } = useController({
    name,
    control
  });

  const handleDisabledDate = (curr) => {
    // console.log('form : ', moment(curr).hour());
    // console.log('now : ', moment().hour());
    if (disablePast) {
      if (curr && moment(curr).isSameOrBefore()) return true;
    }
    return null;
  };
  return (
    <div className="custom-picker-wrap">
      <TimePicker
        disabledDate={handleDisabledDate}
        {...field}
        className={className}
        format="h:mm a"
        // format="YYYY-MM-DD"
        // 'HH:mm'
        placeholder={placeholder}
        size="large"
        defaultValue={defaultValue}
        bordered={false}
        getPopupContainer={() => document.querySelector(`.${formClassName}`)}
        // value={defaultValue ? defaultValue : field.value}
      />

      {errorMsg && <p className="error-p">{errorMsg}</p>}
    </div>
  );
};

export default CustomAntdTimePicker;
