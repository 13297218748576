const baseLabelTransString = (t, key) => t(`paymentOptions.${key}.label`);

export const paymentTypesValues = {
  cashPayment: 1,
  onlinePayment: 2,
  walletPayment: 3
};
export const merchantPaymentTypes = (t) => {
  return [
    {
      name: baseLabelTransString(t, 'cashPayment'),
      value: '1',
      id: '1'
    },
    {
      name: baseLabelTransString(t, 'onlinePayment'),
      value: '2',
      id: '2'
    }
  ];
};

export const paymentTypesFilter = (t) => {
  return [
    {
      name: baseLabelTransString(t, 'cashPayment'),
      value: '1',
      id: '1'
    },
    {
      name: baseLabelTransString(t, 'onlinePayment'),
      value: '2',
      id: '2'
    }
  ];
};

const paymentTypes = (t) => {
  return [
    ...paymentTypesFilter(t),
    {
      name: baseLabelTransString(t, 'walletPayment'),
      value: '3',
      id: '3'
    }
  ];
};

export default paymentTypes;
