import { useTranslation } from 'react-i18next';
import ArchHeading from '../../../common/arch-heading/ArchHeading';
// import SlickSliderBtns from '../../../utils/SlickSliderBtns';
import SharedProductsSlider from './SharedProductsSlider';

const TrendeyProductsSlider = ({ slides, isLoading, setFetchCount }) => {
  // SlickSliderBtns('trendy-products-slider', false);
  // SlickSliderBtns('trendy-products-slider', isLoading);
  const { t } = useTranslation();
  return (
    <section className="trendy-products-section">
      <div className="custom-container">
        <ArchHeading title={t('homeSections.trending')} />
        <SharedProductsSlider
          slides={slides}
          className="trendy-products-slider"
          setFetchCount={setFetchCount}
        />
      </div>
    </section>
  );
};

export default TrendeyProductsSlider;
