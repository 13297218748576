/* eslint-disable eqeqeq */
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import cashImg from '../../../assets/imgs/icons/cash.png';
import receiptIcon from '../../../assets/imgs/icons/receipt.png';
import refundOptions from '../../../const-values/refundOptions';
import UserIcon from '../../../common/icons/UserIcon';
import MobileIcon from '../../../common/icons/MobileIcon';
import PinIcon from '../../../common/icons/PinIcon';
import productSettingsOptions from '../../../const-values/productSettingsOptions';
import CustomImage from '../../../common/custom-image/CustomImage';
import { Descriptions, Rate } from 'antd';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import errorNotification from '../../../utils/errorNotification';
import { useContext } from 'react';
import checkRes from '../../../utils/checkRes';
import UserContext from '../../../contexts/user-context/UserProvider';
import masterCardImg from '../../../assets/imgs/icons/master-card.svg';
import ShieldIcon from '../../../common/icons/ShieldIcon';
import mapImg from '../../../assets/imgs/icons/map-svg.svg';
import './StoreOrderCard.scss';
import EmailIcon from '../../../common/icons/EmailIcon';
import StoreOrdersContext from '../../../contexts/store-orders-context/StoreOrdersContext';
import RenderOrderStatus from '../../../common/render-order-status/RenderOrderStatus';
import { paymentTypesValues } from '../../../const-values/paymentTypes';
import { orderStatusValues } from '../../../const-values/orderStatus';
import AcceptServiceForm from './AcceptServiceForm';
import { CloseCircleOutlined } from '@ant-design/icons';
import acceptOrRejectOrderApi from '../../../apis/stores-apis/acceptOrRejectOrderApi';
import successNotification from '../../../utils/successNotification';
import moment from 'moment';
import renderTime from '../../../utils/renderTime';

const UserOrderCard = ({ card }) => {
  const { t, i18n } = useTranslation();
  const { user } = useContext(UserContext);

  const {
    setFetchOrdersCount,
    setIsRejecting,
    setReceiptModalOpened,
    setConfirmFormOpened,
    confirmFormOpened,
    setReportClientModalOpened,
    selectedOrder,
    setSelectedOrder,
    setAddressMapModalOpened
  } = useContext(StoreOrdersContext);

  const handleAcceptRejectOrderService = (id, statusValue) => {
    setIsRejecting(true);

    const formData = new FormData();
    formData.append('cart_id', id);
    formData.append('status', statusValue);
    customApiRequest(
      acceptOrRejectOrderApi(formData, user?.token, i18n.language),
      (res) => {
        setIsRejecting(false);
        if (checkRes(res)) {
          setFetchOrdersCount((prev) => prev + 1);
          successNotification({
            title: t('successTitle'),
            message: res?.data?.message || 'العملية تمت بنجاح'
          });
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || t('errorMessage')
          });
        }
      },
      (error) => {
        setIsRejecting(false);
        errorNotification({
          title: 'Error!',
          message: error?.response?.data?.message || 'Try again later'
        });
      }
    );
  };

  const renderRefundOption = (p) => {
    if (refundOptions(t)?.length > 0) {
      const foundRefundOption = refundOptions(t).find(
        (item) => item.id == p?.refundData?.refundOption
      );
      if (foundRefundOption)
        return (
          <p className="refund-title">
            <ShieldIcon color="#64022C" />
            {foundRefundOption.title}{' '}
            {p?.refundData?.expireDate && (
              <>
                {t('ordersPage.end')}
                {moment(p.refundData.expireDate).format('LL ( h : mm a ) ')}
              </>
            )}
          </p>
        );
      return '';
    }
  };
  const getOptionPrice = (obj) => {
    if (productSettingsOptions(t)?.length > 0) {
      const foundOption = productSettingsOptions(t).find(
        (item) => item?.value == obj?.optionType
      );
      return foundOption;
    }
    return null;
  };
  const renderProductSettingsOptions = (obj) => {
    if (productSettingsOptions(t)?.length > 0) {
      const foundOption = getOptionPrice(obj);
      if (foundOption)
        return (
          <span className="option-p">
            <CheckOutlined /> {foundOption.name}
          </span>
        );
      return '';
    }
  };

  const renderClientInfo = () => {
    return (
      <div className="info-total-wrapper">
        <div className="user-info-wrap">
          <p className="title">{t('ordersPage.clientInfo')}</p>
          <div className="data">
            <div className="name-address">
              <div className="name">
                <div className="label">
                  <UserIcon color="#6D6D6D" />
                  {t('ordersPage.clientName')} :
                </div>
                <div className="value">{card?.user?.name}</div>
              </div>
              <div
                onClick={() => {
                  setSelectedOrder(card);
                  setAddressMapModalOpened(true);
                }}
                className="address"
              >
                <div className="label">
                  <PinIcon color="#6D6D6D" />
                  {t('ordersPage.clientAddress')} :
                </div>
                <div className="value">
                  <p>
                    {card?.address?.country && (
                      <span>{card.address.country} | </span>
                    )}
                    {card?.address?.city && <span>{card.address.city} | </span>}
                    {card?.address?.area && <span>{card.address.area} | </span>}
                    {card?.address?.streetName && (
                      <span>{card.address.streetName} | </span>
                    )}
                    {card?.address?.buildingNumber && (
                      <span>{card.address.buildingNumber} | </span>
                    )}
                  </p>

                  <span className="map-span">
                    {t('ordersPage.map')} <img src={mapImg} alt="map" />
                  </span>
                </div>
              </div>
            </div>

            <div className="phone-email">
              <div className="phone">
                <div className="label">
                  <MobileIcon color="#6D6D6D" />
                  {t('ordersPage.phone')} :
                </div>
                <div className="value">{card?.user?.phone}</div>
              </div>
              <div className="email">
                <div className="label">
                  <EmailIcon color="#6D6D6D" />
                  {t('ordersPage.clientMail')} :
                </div>
                <div className="value">{card?.user?.email}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="total-wrapper">
          <Descriptions column={1} title="" bordered>
            <Descriptions.Item
              label={i18n.language === 'ar' ? 'إجمالى الرسوم' : 'Tax Price'}
            >
              <div className="img-name-wrap">
                <p>
                  {card?.taxPrice || 0} {t('currency.sar')}
                </p>
              </div>
            </Descriptions.Item>
            <Descriptions.Item
              label={i18n.language === 'ar' ? 'إجمالى الفاتورة' : 'Total Price'}
            >
              <div className="img-name-wrap">
                <p>
                  {card?.totalPrice || 0} {t('currency.sar')}
                </p>
              </div>
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
    );
  };

  const renderAccetpRejectBtns = (orderService) => {
    if (orderService?.isOrder == orderStatusValues?.new) {
      return (
        <div className="accept-reject-btns">
          <button
            className="accept-service-btn"
            onClick={() => {
              handleAcceptRejectOrderService(
                orderService?.id,
                orderStatusValues?.accepted
              );
            }}
          >
            {t('ordersPage.accept')}
          </button>
          <button
            className="reject-service-btn"
            onClick={() => {
              handleAcceptRejectOrderService(
                orderService?.id,
                orderStatusValues?.rejectedByStore
              );
            }}
          >
            {t('ordersPage.reject')}
          </button>
        </div>
      );
    } else if (
      orderService?.isOrder == orderStatusValues?.accepted &&
      orderService?.id != selectedOrder?.id
    ) {
      return (
        <div className="accept-reject-btns">
          <button
            className="accept-service-btn"
            onClick={() => {
              setSelectedOrder(orderService);
              setConfirmFormOpened(true);
            }}
          >
            {t('ordersPage.confirm')}
          </button>
        </div>
      );
    } else if (
      orderService?.isOrder == orderStatusValues?.accepted &&
      confirmFormOpened &&
      orderService?.id == selectedOrder?.id
    )
      return (
        <div className="accept-form-close-btn-wrapper">
          <AcceptServiceForm />
          <button
            onClick={() => {
              setConfirmFormOpened(false);
              setSelectedOrder(null);
            }}
            className="close-form-btn"
          >
            <CloseCircleOutlined />
          </button>
        </div>
      );
  };

  const renderOrderProducts = () => {
    return card.products.map((p) => {
      return (
        <div key={p?.id} className="product-wrap">
          <div className="img-order-status-wrapper">
            <CustomImage
              style={{
                width: 122,
                height: 122,
                objectFit: 'cover',
                objectPosition: 'center center',
                borderRadius: 8
              }}
              className="p-logo"
              src={
                p?.product?.productImages?.length > 0
                  ? p.product.productImages[0]?.image
                  : p?.product?.logo
              }
            />
            <div className="status-wrap">
              <RenderOrderStatus status={p?.isOrder} />
            </div>
          </div>
          <div className="p-data">
            <div className="main-data">
              <div className="p-name">{p?.product?.name}</div>
              <div className="cat-filter-cat">
                {p?.product?.cat} - {p?.product?.catFilter}
              </div>
              <div className="item-price">
                <div className="label">{t('ordersPage.itemPrice')} :</div>
                <div className="value">
                  {/* <span>{p?.product?.price}</span> */}
                  <span>{p?.servicePrice}</span>
                  <span>{t('currency.sar')}</span>
                </div>
              </div>
              <div className="option-price">
                <span className="label">{renderProductSettingsOptions(p)}</span>
                {p?.optionPrice && Number(p?.optionPrice) > 0 ? (
                  <span className="value">
                    <span>{p?.optionPrice || 0}</span>
                    <span>{t('currency.sar')}</span>
                  </span>
                ) : null}
              </div>
              <div className="refund-wrap">{renderRefundOption(p)}</div>

              <div className="address-wrap">
                {card?.store?.country &&
                card?.store?.city &&
                card?.store?.street &&
                card?.store?.storeNumber ? (
                  <p>
                    <PinIcon color="#6D6D6D" />
                    {t('ordersPage.myAddress')} : {card.store.country} |{' '}
                    {card.store?.city} | {card.store.street} |{' '}
                    {card.store.storeNumber}
                  </p>
                ) : (
                  <p>
                    {card?.store?.address && (
                      <>
                        <PinIcon color="#6D6D6D" /> {t('ordersPage.myAddress')}{' '}
                        {card.store.address}
                      </>
                    )}
                  </p>
                )}
              </div>
            </div>

            <div className="side-data">
              <div className="side-data-list">
                <div className="quantity-data">
                  <div className="label">{t('ordersPage.quantity')}</div>
                  <div className="value">{p?.quantity}</div>
                </div>
                <div className="total-data">
                  <div className="label">{t('ordersPage.total')}</div>
                  <div className="value">
                    {p?.totalPrice} <span>{t('currency.sar')}</span>
                  </div>
                </div>
                <div className="delivery-wrapper">
                  <p className="label">{t('ordersPage.delivery')}</p>
                  <div className="value">
                    {p?.optionPrice || 0}
                    <span>{t('currency.sar')}</span>
                  </div>
                </div>
                <div className="inform-date-wrapper">
                  <p className="label">{t('ordersPage.informDate')}</p>
                  <div className="date value" dir="ltr">
                    {p?.date}
                    {p?.time && `- ${renderTime(p.time, i18n)}`}
                  </div>
                </div>
              </div>

              {p?.isOrder === orderStatusValues?.canceledByUser &&
                p.cancelDate && (
                  <p className="cancel-date">
                    {t('ordersPage.cancelDate')} :{' '}
                    <span>( {p.cancelDate} )</span>
                  </p>
                )}

              <div className="report-accept-reject-btns">
                <button
                  onClick={() => {
                    setReportClientModalOpened(true);
                    setSelectedOrder(p);
                  }}
                  className="report-client"
                >
                  {t('ordersPage.reportClient')}
                </button>
                {renderAccetpRejectBtns(p)}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const customApiRequest = useCustomApiRequest();

  return (
    <div className="store-order-card">
      <div className="card-header">
        <div className="nums-method-wrap">
          <div className="num">
            <div className="label">{t('ordersPage.orderNumber')}</div>
            <div className="value"># {card?.id}</div>
          </div>
        </div>

        <div className="dates-wrap">
          <div className="place-date-wrapper">
            <p className="label">{t('ordersPage.placedDate')}</p>
            <div className="date value">
              {moment(card?.created_at).format('LL')}
            </div>
          </div>
        </div>

        <div className="method">
          {/* <div className="label">{t('ordersPage.paymentType')}</div> */}
          {card?.paymentType == paymentTypesValues?.cashPayment ? (
            <div className="img-label-wrap">
              <img src={cashImg} alt="cash" />
              <span>{t('ordersPage.cash')}</span>
            </div>
          ) : (
            <div className="img-label-wrap">
              <img src={masterCardImg} alt="online" />
              <span>{t('ordersPage.mastercard')}</span>
            </div>
          )}
        </div>

        {/* <div className="status-wrap">
          <RenderOrderStatus status={card?.status} />
        </div> */}
      </div>

      {card?.products?.length > 0 && (
        <div className="order-products">{renderOrderProducts()}</div>
      )}

      <div className="card-footer">
        <div className="btns-wrap">
          <button
            onClick={() => {
              setReceiptModalOpened(true);
              setSelectedOrder(card);
            }}
            className="receipt-btn"
          >
            <img src={receiptIcon} alt="" />
            {t('ordersPage.orderReceipt')}
          </button>
        </div>

        <div className="rate-wrap">
          <Rate value={card?.rate} disabled />
          <p>
            {t('ordersPage.clientRate')} ( {card?.rate} )
          </p>
        </div>
      </div>

      {renderClientInfo()}
    </div>
  );
};

export default UserOrderCard;
