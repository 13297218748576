/* eslint-disable eqeqeq */
import React, { useContext, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import accountTypes from '../../../const-values/accountTypes';
import UserContext from '../../../contexts/user-context/UserProvider';
import './MerchentDashboardCard.scss';

const MerchentDashboardCard = ({
  id,
  title,
  to,
  icon,
  closeAccountModalOpened,
  setCloseAccountModalOpened
}) => {
  const { user } = useContext(UserContext);
  const renderInsideLink = () => {
    return (
      <div className="inside-card-wrap">
        <div className="icon-wrap">{icon}</div>
        <div className="card-title">{title}</div>
      </div>
    );
  };

  return (
    <div
      className={`merchent-dashboard-card ${
        user?.accountType == accountTypes()?.merchent ? 'merchent' : 'employee'
      }`}
    >
      {to ? (
        <RouterLink className="link-card shared-card" to={to}>
          {renderInsideLink()}
        </RouterLink>
      ) : (
        <div
          onClick={() => {
            if (id == 8) setCloseAccountModalOpened(true);
          }}
          className="not-link-card shared-card"
        >
          {renderInsideLink()}
        </div>
      )}
    </div>
  );
};

export default MerchentDashboardCard;
